<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="M3.292 2.4c-1.88 6.118-.76 10.646 3.064 11.82.344.106.662.176.97.23a6 6 0 0 1-.192-1.475c0-.794.11-1.55.312-2.267a30 30 0 0 0-1.712-3.066.8.8 0 0 1 .223-1.108.797.797 0 0 1 1.108.224c.053.079.574.872 1.202 2.034.701-1.181 1.703-2.186 2.94-2.956C9.785 4.376 7.244 3.19 3.292 2.4m14.287 3.338c-4.659 0-8.845 2.702-8.845 7.237 0 1.382.697 2.686 1.681 3.694-1.07 2.233-1.581 3.898-1.581 3.898a.8.8 0 1 0 1.531.466s1.1-3.577 3.467-7.192c1.018-1.532 2.271-3.113 3.818-4.568a.801.801 0 0 1 1.097 1.166c-2.736 2.572-4.538 5.625-5.638 7.948a4.2 4.2 0 0 0 1.255.218c4.412 0 7.237-2.682 7.237-8.847v-4.02z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'New',
})
</script>
