<template>
    <div :class="$style.adBlock" :id="id"/>
</template>
<script lang="ts" setup>
import {
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch,
} from 'vue'
import { useAdsStore } from '@/store/ads-store'
import { getRandomNumber } from '@/utils/helpers'
import type { Banner } from '@/modules/adv/banner'
import type { PageAdSize } from '@/modules/adv'
import { useRoute } from 'vue-router'

const adsStore = useAdsStore()

const banner = ref<Banner>()

const route = useRoute()

type AdBlockProps = {
    type?: 'Sidebar',
    refresh?: number,
    sizes?: PageAdSize[],
}

const props = withDefaults(defineProps<AdBlockProps>(), {
    type: 'Sidebar',
})

const id = ref('')

async function updateBanner() {
    const rect = document.getElementById(id.value)?.getBoundingClientRect()
    if (rect && rect.height > 0 && !banner.value) {
        banner.value = await adsStore.adMediator?.preparePageAd({
            type: props.type,
            refresh: props.refresh,
            el: id.value,
            sizes: props.sizes,
        })
    } else if (rect && rect.height === 0 && banner.value) {
        banner.value?.destroy()
        banner.value = undefined
    }
}
watch(() => route.fullPath, () => {
    // Ожидаем отрисовки страницы
    setTimeout(updateBanner, 100)
})

onBeforeMount(() => {
    id.value = `ad_${getRandomNumber(10000, 99999, 16)}`
})
onMounted(async () => {
    if (!adsStore.adMediator) {
        return
    }
    updateBanner()
})
onBeforeUnmount(() => {
    banner.value?.destroy()
})

</script>

<style module>
.adBlock {
    min-width: 1px;
    min-height: 1px;
}
</style>
