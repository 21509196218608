export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Online Games at Playgama"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playgama features the latest and best free online games. You can enjoy playing fun games without interruptions from downloads, intrusive ads, or pop-ups. Just load up your favorite games instantly in your web browser and enjoy the experience."])},
        "img_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fennek"])}
      }
    }
  })
}
