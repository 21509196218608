<template>
    <div :class="$style.task_widgets" v-if="isAuthorized && user && showTasks">
        <template v-if="tasksError">
            <Typography type="label" size="s" :class="$style.title" accent :responsive="false">{{t('title')}}</Typography>
            <span :class="$style.inner_sep"></span>
            <div :class="$style.error_container">
                <Typography type="label" size="l" accent>{{t('error_title')}}</Typography>
                <Button
                    color="graphite"
                    @click="onReloadClick"
                    visual="fill"
                >
                    {{ t('error_button') }}
                </Button>
            </div>
        </template>
        <template v-else>
            <template v-if="!allTasksClaimed">
                <component :is="props.redirect ? RouterLink : 'div'" to="/profile" :class="$style.title">
                    <Typography type="label" size="s" accent :responsive="false">{{t('title')}}</Typography>
                    <Icon :class="$style.chevron" name="chevron_r" v-if="props.redirect"/>
                </component>
                <template v-for="task in tasksToShow" :key="task.task_id">
                    <span :class="$style.inner_sep"></span>
                    <TaskWidget :task="task" @claimTask="onTaskClaimed" :mode="props.mode" />
                </template>
            </template>
            <slot v-else />
        </template>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import { computed } from 'vue'
import { useUserStore } from '@/store/user-store/user-store'
import Icon from '@/components_new/Icon/Icon.vue'
import { RouterLink } from 'vue-router'
import TaskWidget from '@/components_new/TaskItems/TaskWidget.vue'
import Button from '@/components_new/Button.vue'
import { showTasks } from '@/constants/general'
import { tasksTranslation } from '@/components_new/TaskItems/utils'
import { useTasksStore } from '@/components_new/TaskItems/store'

interface TasksWidgetProps {
  showOnlyFirst?: boolean,
  redirect?: boolean,
  appearance?: 'default' | 'accent'
}

const props = withDefaults(
    defineProps<TasksWidgetProps>(),
    {
        appearance: 'default',
    },
)

const userStore = useUserStore()
const { onTaskClaimed, onReloadClick } = useTasksStore()
const {
    tasks, tasksError, isAuthorized, user,
} = storeToRefs(userStore)

const { t } = useI18n({
    messages: {
        en: {
            ...tasksTranslation.en,
            title: 'Missions',
        },
    },
})

const notClaimedTasks = computed(() => tasks.value.filter((task) => task.status !== 'CLAIMED'))
const allTasksClaimed = computed(() => notClaimedTasks.value.length === 0 && tasks.value.length > 0)
const tasksToShow = computed(() => {
    if (allTasksClaimed.value) {
        return []
    }
    return props.showOnlyFirst ? notClaimedTasks.value.slice(0, 1) : notClaimedTasks.value
})

</script>

<style module>

.task_widgets {
  border-radius: 16px;
  background-color: #17171A;
  padding: 12px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding-bottom: 12px;
}

.title:hover .chevron {
  color: white;
}

.inner_sep {
  height: 1px;
  display: block;
  background-color: rgba(255, 255, 255, 0.08);
}

.chevron {
  height: 16px;
  margin-left: auto;
  color: rgba(255, 255, 255, 0.6);
}

.error_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 12px;
  background-color: #17171A;
  padding: 12px;
  gap: 8px;
}

</style>
