<template>
    <Game :key="key"/>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import Game from './Game.vue'

const route = useRoute()

// When this key is changed, Game component will be re-created
const key = computed(() => route.params.game as string)

</script>
