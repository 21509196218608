import { GameType } from '@/types'
import type { Ref } from 'vue'

function getMetaDescription(title?: string) {
    // eslint-disable-next-line max-len
    return `Enjoy ${title} on Playgama! Dive into an exciting experience with no downloads or registrations required. ✨ Play now on desktop, tablet, and mobile. 🕹️`
}
function getMetaTitle(title?: string) {
    return `Play ${title} Online for Free | Playgama 🎮`
}

function getMetaURL(hru?: string) {
    return `${process.env.VUE_APP_HOST_URL}/game/${hru}`
}
type GenerateGameHeadParams = {
    game: Ref<GameType|undefined>
}
export function generateHead({ game }: GenerateGameHeadParams) {
    if (!game) {
        return {}
    }
    return {
        title: () => getMetaTitle(game.value?.title),
        meta: [
            { name: 'description', content: () => getMetaDescription(game.value?.title) },
            { property: 'og:title', content: () => getMetaTitle(game.value?.title) },
            { property: 'og:description', content: () => getMetaDescription(game.value?.title) },
            { property: 'og:url', content: () => getMetaURL(game.value?.hru) },
            { property: 'og:type', content: 'website' },
            { property: 'og:image', content: () => game.value?.preview },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:title', content: () => getMetaTitle(game.value?.title) },
            { name: 'twitter:description', content: () => getMetaDescription(game.value?.title) },
            { name: 'twitter:image', content: () => game.value?.preview },
        ],
        link: [
            {
                rel: 'canonical',
                href: () => getMetaURL(game.value?.hru),
            },
        ],
        script: game.value?.hru === 'lucy-the-dog-rescue-playgama' ? [
            {
                async: true,
                src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2268865789004431',
                crossorigin: 'anonymous' as const,
            },
        ] : undefined,
    }
}
