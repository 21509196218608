<template>
    <div :class="$style.container">
        <header :class="$style.header">
            <span v-if="isUserInfoLoaded" :class="$style.rightBlock">
                <template v-if="isAuthorized && user">
                    <RouterLink
                        :class="$style.userInfo"
                        to="/profile"
                    >
                        <UserAvatar
                            :class="$style.avatar"
                            :avatar="user.avatar"
                            :firstName="user.firstName"
                            :lastName="user.lastName"
                        />
                        <span :class="$style.userName">{{ user.firstName }} {{ user.lastName }}</span>
                    </RouterLink>
                </template>
                <Button
                    v-else
                    :class="$style.loginBtn"
                    :title="t('login')"
                    text
                    size="s"
                    @click="openLoginWindow"
                />
            </span>
        </header>
    </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user-store/user-store'
import Button from '@/components/Button.vue'
import UserAvatar from '@/components_new/UserAvatar.vue'
import { useLayoutStore } from '@/store/layout-store'

const userStore = useUserStore()
const layoutStore = useLayoutStore()
const { t } = useI18n()

const { setSignInModalOpened } = layoutStore

const {
    user, isAuthorized, isUserInfoLoaded,
} = storeToRefs(userStore)

function openLoginWindow() {
    setSignInModalOpened(true)
}
</script>
<style module>
.container {
    position: relative;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 var(--gap-regular);
    margin-bottom: var(--gap-large);
    box-sizing: border-box;
    background: linear-gradient(0deg, var(--black) 0%, var(--dark-purple) 100%);
    background-size: contain;
    border: 1px solid var(--dark-purple-50);
    border-radius: var(--radius-large);
    line-height: 0;
}

.rightBlock {
    display: flex;
    align-items: center;
    line-height: 0;
}

.userInfo {
    display: inline-flex;
    margin-left: auto;
    align-items: center;
    cursor: pointer;
}

.loginBtn {
    display: inline-flex;
}

.avatar {
    margin-right: var(--gap-small);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: center center / cover var(--accent-purple);
}

@media (--mobile) {

    .header {
        background: linear-gradient(0deg, var(--black) 0%, var(--dark-purple) 100%);
    }
}

@media (--tablet) {
  .userName {
    display: block;
  }
}
</style>
<i18n lang="json">
{
    "en": {
        "tasksToday": "Tasks Today",
        "login": "Login"
    }
}
</i18n>
