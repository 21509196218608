export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Playgama"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Playgama for earn coins and track your game progress"])},
        "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in Playgama"])}
      }
    }
  })
}
