<template>
    <!-- eslint-disable max-len -->
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#fff" d="m12.214 13.442-5.246-.004c-.01.196 0 .396 0 .592v6.076c-.016 1.112-1.528 1.194-1.71.144-.03-.17-.012-.367-.012-.541l.003-15.37c.022-.447.314-.766.756-.835l.15-.004c.669.013.818.625.809 1.173h10.481c.112 0 .23-.006.34.008.436.055.785.495.703.934-.033.179-.15.354-.239.51l-.802 1.384c-.086.15-.926 1.58-.922 1.638.001.015.04.066.05.084l1.618 2.684c.126.209.304.43.304.685 0 .412-.3.77-.707.833-.174.026-.372.009-.549.009z"/></svg>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'AllGames',
})
</script>
