<template>
    <nav :class="styles.menu">
        <CategoryButton
            v-for="item in props.categories"
            :key="item.name"
            :name="t(item.name)"
            :link="item.href"
            :isActive="item.href === route.path"
            :icon="item.icon"
            :textStyle="categoryTextStyle"
        />
    </nav>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import {
    useCssModule,
    computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import type { CategoryListType } from '@/types'
import CategoryButton from '@/components/CategoryButton.vue'
import { categoriesTranslation } from '@/utils/translations/categories'

interface MenuProps {
    categories: CategoryListType[],
    isBigItem?: boolean,
}

const props = withDefaults(defineProps<MenuProps>(), {
    isBigItem: false,
})

const styles = useCssModule()
const { t } = useI18n({ messages: categoriesTranslation })
const route = useRoute()

const categoryTextStyle = computed(() => {
    if (props.isBigItem) {
        return 'title'
    }
    return 'accent'
})

</script>

<style module>
.menu {
    display: flex;
    flex-direction: column;
}

.menu a {
    margin-top: var(--gap-xsmall);
}

.menu a:first-child {
    margin-top: 0;
}

@media (--mobile) {
    .menu {
        padding: var(--gap-small);
        margin: 0;
    }
}
</style>
