export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friends and get rewards"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your unique link and earn Fennec Coins when friends join and play!"])},
        "btn_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
        "btn_label_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied!"])},
        "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite friends"])},
        "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your link with friends"])},
        "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends sign up and play"])},
        "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You both earn Fennec Coins!"])}
      }
    }
  })
}
