<template>
    <Layout>
        <main :class="$style.container">
            <CategoryHeader :category="category"/>
            <div :class="$style.pagesAndSeo">
                <Typography
                    v-if="category?.seoText"
                    is="div"
                    type="paragraph"
                    size="xs"
                    :class="$style.seoText"
                >
                    <p v-html="category.seoText"/>
                </Typography>
                <div :class="$style.pages">
                    <template
                        v-for="(page, index) in pages"
                        :key="index"
                    >
                        <div
                            v-if="(page instanceof Array) || showPlaceholders"
                            :class="$style.page"
                            :data-page-number="index + 1"
                            :id="`page-${index + 1}`"
                            v-intersection-observer="onPageIntersection"
                        >
                            <div :class="$style.grid">
                                <template v-if="(page instanceof Array)">
                                    <template v-for="(game, index) in page" :key="game.hru">
                                        <GameCard
                                            :class="[bigCards.includes(index) ? $style.bigCard : '']"
                                            :game="game"
                                            :appearance="bigCards.includes(index) ? 'big' : 'medium'"
                                            responsive
                                        />
                                    </template>
                                </template>
                                <template v-else>
                                    <template v-for="(_, index) in placeholderPage" :key="`placeholder-${index}`">
                                        <GameCard
                                            :class="[bigCards.includes(index) ? $style.bigCard : '', $style.placeholderCard]"
                                            :game="undefined"
                                            :appearance="bigCards.includes(index) ? 'big' : 'medium'"
                                            responsive
                                        />
                                    </template>
                                </template>
                            </div>
                        </div>
                        <PromoCategories v-if="index === 0" :class="$style.promo"/>
                    </template>
                    <div
                        :class="$style.page"
                        :data-page-number="pages.length + 1"
                        v-if="showPlaceholders && nextPagePlaceholder"
                        v-intersection-observer="onPageIntersection"
                    >
                        <div :class="$style.grid">
                            <template v-for="(_, index) in placeholderPage" :key="`placeholder-${index}`">
                                <GameCard
                                    :class="[bigCards.includes(index) ? $style.bigCard : '', $style.placeholderCard]"
                                    :game="undefined"
                                    :appearance="bigCards.includes(index) ? 'big' : 'medium'"
                                    responsive
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <TheEnd/>
        </main>
    </Layout>
</template>
<script setup lang="ts">
import {
    onServerPrefetch,
    onMounted,
    computed,
    watch,
    ref,
} from 'vue'
import { storeToRefs } from 'pinia'
import {
    useRoute,
    useRouter,
    // eslint-disable-next-line import/no-duplicates
} from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { vIntersectionObserver } from '@vueuse/components'

import { PageStatus } from '@/store/app-store'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import TheEnd from '@/components_new/TheEnd/TheEnd.vue'
import GameCard from '@/components_new/GameCard.vue'
import PromoCategories from '@/components_new/PromoCategories/PromoCategories.vue'
import { useCategoriesStore } from '@/store/categories-store'
import Typography from '@/components_new/Typography.vue'
import { useCategoryPageStore } from './category-page-store'
import CategoryHeader from './components/CategoryHeader.vue'

const categoriesStore = useCategoriesStore()
const categoryPageStore = useCategoryPageStore()

const { t } = useI18n()

const { pages, pageSize, category } = storeToRefs(categoryPageStore)
const placeholderPage = computed(() => Array(pageSize.value).fill(null))
const nextPagePlaceholder = computed(() => {
    if (category.value) {
        return pages.value.length < category.value.totalPages
    }
    return true
})

const $route = useRoute()
const router = useRouter()

function onPageIntersection([{ isIntersecting, target }]: IntersectionObserverEntry[]) {
    const pageIndexString = (target as HTMLElement).dataset.pageNumber
    if (!pageIndexString) return
    const pageIndex = parseInt(pageIndexString, 10)
    if (isIntersecting) {
        router.replace({
            query: {
                ...$route.query,
                page: pageIndex > 1 ? pageIndex : undefined,
            },
        })
    }
}

const activePage = computed(() => parseInt($route.query.page as string, 10) || 1)

function routeChanged(prefetchNextPage = false) {
    const categoryName = $route.params.category as string
    const page = parseInt($route.query.page as string, 10) || 1
    const mainRequest = categoryPageStore.setCategory({
        categoryName,
        page,
    })

    if (prefetchNextPage) {
        categoryPageStore.setCategory({
            categoryName,
            page: page + 1,
        })
    }

    return mainRequest
}

function getMetaURL(id: string, page: number) {
    return `${process.env.VUE_APP_HOST_URL}/category/${id}${page > 1 ? `?page=${page}` : ''}`
}

const pageMeta = computed(() => {
    if (!category.value || !category.value.id) {
        return {}
    }

    const categoryId = category.value.id

    const links = [
        {
            rel: 'canonical',
            href: () => getMetaURL(categoryId, activePage.value),
        },
    ]

    if (activePage.value > 1) {
        links.push({
            rel: 'prev',
            href: () => getMetaURL(categoryId, activePage.value - 1),
        })
    }
    if (activePage.value < category.value.totalPages) {
        links.push({
            rel: 'next',
            href: () => getMetaURL(categoryId, activePage.value + 1),
        })
    }

    const pageStr = activePage.value > 1
        ? ` | ${t('page', { page: activePage.value })}`
        : ''

    return {
        title: () => `${category.value?.metaTitle}${pageStr}`,
        meta: [
            {
                name: 'title',
                content: `${category.value?.metaTitle}${pageStr}`,
            },
            {
                name: 'description',
                content: `${category.value?.metaDescription}${pageStr}`,
            },
        ],
        link: links,
    }
})

useHead(pageMeta)

watch(
    () => $route.params.category,
    () => routeChanged(true),
)

watch(
    () => $route.query.page,
    () => routeChanged(true),
)

// depends on the pageSize, to make cards fill all the space without gaps
// on 2, 3, 4, 5, 6 columns
const bigCards = ref<number[]>([0, 13, 14, 25])

onServerPrefetch(async () => {
    try {
        await Promise.all([
            routeChanged(),
            categoriesStore.setMainData(),
        ])
    } catch (e) {
        if (typeof e === 'number') {
            throw new Error(e as PageStatus)
        } else {
            throw new Error(500)
        }
    }
})

const showPlaceholders = ref(false)

onMounted(async () => {
    showPlaceholders.value = true
    if (category.value?.id !== $route.params.category) {
        routeChanged()
    }
    await categoriesStore.setMainData()
})

</script>
<style module>
.container {
    --pages-gap: 8px;
}

.container, .pagesAndSeo, .pages {
    display: flex;
    flex-direction: column;
    gap: var(--pages-gap);
}

.pagesAndSeo {
    flex-direction: column-reverse;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-auto-flow: dense;
    gap: 8px;
    --cards-gap: 8; /* unitless, consumed by GameCard component */
    position: relative;
}

.bigCard {
    grid-column: span 2;
    grid-row: span 2;
}

.promo {
    margin: calc(24px - var(--pages-gap)) 0;
}

.seoText {
    background: #17171A;
    padding: 20px 16px;
    border-radius: 16px;
    color: rgba(255, 255, 255, 0.8);
}

.seoText h2 {
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: var(--new-font-size-header-xs);
    font-weight: var(--new-font-weight-header-xs);
    line-height: var(--new-line-height-header);

    color: #fff;
}

.seoText h2:first-child {
    margin-top: 0;
}
</style>
<i18n lang="json">
{
    "en": {
        "page": "page {page}"
    }
}
</i18n>
