<template>
    <div :class="$style.sidebar">
        <GameCardsList
            v-if="props.recentlyGames?.length"
            :class="$style.suggest"
            appearance="aside"
            :title="t('recommended')"
            :games="props.recentlyGames"
        />
        <h2 class="adHeader" :class="$style.adHeader">{{t('ads')}}</h2>
        <AdBlock
            :class="$style.adBlock"
            type="sidebar_desktop"
            :sizes="bannerSizes"
            :refresh="REFRESH_TIMEOUT"
        />
    </div>
</template>

<script setup lang="ts">
import {
    onMounted, ref,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { SimplifiedGame } from '@/types'
import { categoriesTranslation } from '@/utils/translations/categories'
import GameCardsList from '@/components/GameCardsList.vue'
import type { PageAdSize } from '@/modules/adv'
import AdBlock from './AdBlock.vue'

const REFRESH_TIMEOUT = 2 * 60 // in seconds
const WINDOW_HEIGHT_FOR_600_BANNERS = 900
const bannerSizes = ref<PageAdSize[]>([[300, 250]])

onMounted(() => {
    const height = window.innerHeight
    if (height > WINDOW_HEIGHT_FOR_600_BANNERS) {
        bannerSizes.value.push([160, 600], [120, 600], [300, 600])
    }
})

const { t } = useI18n({
    messages: {
        en: {
            ...categoriesTranslation.en,
            ads: 'Ads',
        },
    },
})

interface RightSideProps {
    recentlyGames: SimplifiedGame[] | null,
}

const props = defineProps<RightSideProps>()
</script>

<style module>

.sidebar {
  position: fixed;
  top: var(--gap-large);
  bottom: var(--gap-large);
  width: 332px;
}

.suggest {
    margin-top: var(--gap-large);
}

.suggest:first-child {
    margin-top: 0;
}

.adHeader {
    margin-top: var(--gap-large);
}

.adBlock {
    margin-top: var(--gap-regular);
    margin-left: auto;
    margin-right: auto;
    width: 320px;
}

@media (--mobile) {
    .sidebar {
        position: static;
    }
}

@media (--desktop-small) {
    .suggest {
        margin-right: var(--gap-negative-regular);
        max-width: 100%;
        overflow-x: hidden;
    }
    .sidebar {
        width: 320px;
    }
}
</style>
