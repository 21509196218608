import { defineStore } from 'pinia'
import { useUserStore } from '@/store/user-store/user-store'

export const useTasksStore = defineStore('tasks', () => {
    const userStore = useUserStore()

    async function onTaskClaimed(taskId: string) {
        await userStore.claimUserTasks(taskId)
    }

    async function onReloadClick() {
        await userStore.getUserTasks()
    }
    return {
        onTaskClaimed,
        onReloadClick,
    }
})
