import { defineStore } from 'pinia'
import { PromoActions } from '@/components_new/PromoItems/types'
import { useLayoutStore } from '@/store/layout-store'
import { useLoggerStore } from '@/store/logger-store'

export const usePromoStore = defineStore('promo', () => {
    const { setInviteModalOpened, setSignInModalOpened } = useLayoutStore()
    const loggerStore = useLoggerStore()

    function setPromoAction(action: PromoActions) {
        if (action === 'sign_in') {
            setSignInModalOpened(true)

            loggerStore.logEvent({
                event: 'custom_event',
                eventName: 'sign-in',
                label: 'promo',
                action: 'click',
            })
        }
        if (action === 'invite') {
            setInviteModalOpened(true)

            loggerStore.logEvent({
                event: 'custom_event',
                eventName: 'invite-friend',
                label: 'promo',
                action: 'click',
            })
        }
    }
    return {
        setPromoAction,
    }
})
