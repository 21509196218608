<template>
    <div :class="$style.container">
        <Typography is="ul" type="label" :accent="true" size="s" :class="$style.breadcrumbs" :responsive="false">
            <li v-for="(item, index) in breadcrumbs" :key="item.title">
                <span v-if="index !== 0" :class="$style.breadcrum_item"  >
                    /
                </span>
                <component
                    :is="item.link ? RouterLink : 'span'"
                    :to="item.link"
                    :class="$style.breadcrum_item"
                    type="label"
                >
                    {{ item.title }}
                </component>
            </li>
        </Typography>
        <Typography is="h1" size="m" >{{ props.category?.name }}</Typography>
        <Typography is="p" size="xs"  v-if="props.category?.description" :class="$style.description">
            {{ props.category?.description }}
        </Typography>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { categoriesTranslation } from '@/utils/translations/categories'
import Typography from '@/components_new/Typography.vue'

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const props = defineProps<{
    category: {
        id: string;
        name: string;
        description?: string;
    } | null;
}>()

const breadcrumbs = computed(() => ([
    {
        title: 'Playgama',
        link: '/',
    },
    {
        title: t(props.category?.id || ''),
    },
]))
</script>
<style module>
.container {
    display: flex;
    padding: 16px 16px 24px 16px;

    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;

    border-radius: 16px;
    background: #17171A;
}

.breadcrumbs {
    margin-bottom: 8px;

    color: rgba(255, 255, 255, 0.4);
}

.breadcrumbs li {
    display: contents;
}

.breadcrum_item {
    padding: 8px 2px;
}

.description {

    margin-top: 12px;
    color: rgba(255, 255, 255, 0.8);
}
</style>
