export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "page_title_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
        "page_title_non_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playgama shop"])},
        "page_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our gaming shop, where you can spend your earned coins on exciting prizes and exclusive gifts! Whether you’re after rare collectibles or fun surprises, our store offers a wide range of rewards to enhance your gaming experience."])}
      }
    }
  })
}
