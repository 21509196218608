<!-- eslint-disable max-len -->
<template>
    <div class="framer-YMdyE framer-vCtKe framer-GDBVd framer-nW5Mn framer-MBtTz" style="display: contents;">
        <div class="framer-2cawnp" style="min-height: 100%; width: auto;">
            <div class="framer-1z05982">
                <div class="framer-hdhq8p">
                    <div class="framer-1lxxvw6">
                        <div class="ssr-variant hidden-43cm73">
                            <div class="framer-pwrz1n">
                                <div data-framer-background-image-wrapper="true"
                                     style="position: absolute; border-radius: inherit; inset: 0px;"><img
                                         src="/landing/SA3xQCiwPoM1UEmAAmuLqT7u87M.webp" alt=""
                                         sizes="(min-width: 1200px) max(233.0508px, 247.5px), (min-width: 750px) and (max-width: 1199px) max(233.0508px, 247.5px), (max-width: 749px) max(167.7966px, 220px)"
                                         data-framer-original-sizes="233.0508px"
                                         style="display: block; width: 100%; height: 100%; border-radius: inherit; object-position: center center; object-fit: cover; image-rendering: auto;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="framer-1jnaxw1" data-framer-component-type="RichTextContainer"
                         style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: rotate(-3deg);">
                        <h1 class="framer-text framer-styles-preset-3nqyhf" data-styles-preset="YAP816Y5n"
                            style="text-align: center;">Dear big tech manager</h1></div>
                    <div class="framer-qwxprw" data-framer-component-type="RichTextContainer"
                         style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                        <h3 class="framer-text framer-styles-preset-12lj5ox" data-styles-preset="YckFIlg3V">I know
                            you‘re always looking for the new instruments to reach new audience and increase
                            retention</h3></div>
                    <div class="framer-e815gr-container">
                        <div class="framer-HYSVJ framer-nW5Mn framer-GDBVd framer-v-1fbhlu" style="display: contents;">
                            <a class="framer-nkeur0 framer-1ch50es" data-framer-name="Basic Blue" href="./" @click="preventedOpenForm"
                               data-highlight="true"
                               style="backdrop-filter: none; background-color: var(--token-48d3b6c8-3544-4d0e-8305-6db8346b8991,rgb(255,221,0)); border-radius: 40px; box-shadow: none;">
                                <div class="framer-unrxkd" data-framer-component-type="RichTextContainer"
                                     style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                    <h2 class="framer-text framer-styles-preset-1wml6uu" data-styles-preset="fVxnimdqP"
                                        style="color: rgb(0, 0, 0);">Join Playgama</h2></div>
                            </a></div>
                    </div>
                </div>
                <div class="framer-1rrqyfm" data-border="true">
                    <div class="ssr-variant hidden-39w2c5 hidden-43cm73">
                        <div class="framer-awdhnu" data-framer-name="image" style="transform: translateX(-50%);">
                            <div data-framer-background-image-wrapper="true"
                                 style="position: absolute; border-radius: inherit; inset: 0px;"><img
                                     src="/landing/yET8QhRetfGCbfru03pbbg6HI.webp" alt=""
                                     sizes="(min-width: 1200px) 420px, (min-width: 750px) and (max-width: 1199px) 420px, (max-width: 749px) 272.5px"
                                     loading="lazy" data-framer-original-sizes="420px"
                                     style="display: block; width: 100%; height: 100%; border-radius: inherit; object-position: center center; object-fit: cover; image-rendering: auto;">
                            </div>
                            <div class="framer-1jztgns" style="opacity: 1; transform: perspective(1200px);">
                                <div data-framer-component-type="SVG" data-framer-name="shine" class="framer-wlc156"
                                     style="image-rendering: pixelated; flex-shrink: 0; fill: black; color: black;">
                                    <div class="svgContainer" style="width: 100%; height: 100%; aspect-ratio: inherit;">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 240 240"
                                             style="width: 100%; height: 100%;">
                                            <use href="#svg1625808357_502"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ssr-variant hidden-2cawnp hidden-43cm73">
                        <div class="framer-awdhnu" data-framer-name="image" style="transform: translateX(-50%);">
                            <div data-framer-background-image-wrapper="true"
                                 style="position: absolute; border-radius: inherit; inset: 0px;"><img
                                     src="/landing/yET8QhRetfGCbfru03pbbg6HI.webp?scale-down-to=512" alt=""
                                     sizes="(min-width: 1200px) 420px, (min-width: 750px) and (max-width: 1199px) 420px, (max-width: 749px) 272.5px"
                                     data-framer-original-sizes="420px"
                                     style="display: block; width: 100%; height: 100%; border-radius: inherit; object-position: center center; object-fit: cover; image-rendering: auto;">
                            </div>
                            <div class="framer-1jztgns" style="opacity: 1; transform: perspective(1200px);">
                                <div data-framer-component-type="SVG" data-framer-name="shine" class="framer-wlc156"
                                     style="image-rendering: pixelated; flex-shrink: 0; fill: black; color: black;">
                                    <div class="svgContainer" style="width: 100%; height: 100%; aspect-ratio: inherit;">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 240 240"
                                             style="width: 100%; height: 100%;">
                                            <use href="#svg1625808357_502"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ssr-variant hidden-2cawnp hidden-39w2c5">
                        <div class="framer-awdhnu" data-framer-name="image" style="transform: translateX(-50%);">
                            <div data-framer-background-image-wrapper="true"
                                 style="position: absolute; border-radius: inherit; inset: 0px;"><img
                                     src="/landing/yET8QhRetfGCbfru03pbbg6HI.webp?scale-down-to=512" alt=""
                                     sizes="(min-width: 1200px) 420px, (min-width: 750px) and (max-width: 1199px) 420px, (max-width: 749px) 272.5px"
                                     data-framer-original-sizes="272.5px"
                                     style="display: block; width: 100%; height: 100%; border-radius: inherit; object-position: center center; object-fit: cover; image-rendering: auto;">
                            </div>
                            <div class="framer-1jztgns" style="opacity: 1; transform: perspective(1200px);">
                                <div data-framer-component-type="SVG" data-framer-name="shine" class="framer-wlc156"
                                     style="image-rendering: pixelated; flex-shrink: 0; fill: black; color: black;">
                                    <div class="svgContainer" style="width: 100%; height: 100%; aspect-ratio: inherit;">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 240 240"
                                             style="width: 100%; height: 100%;">
                                            <use href="#svg1625808357_502"></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="framer-13kyow6" data-framer-component-type="RichTextContainer"
                         style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: rotate(3deg);">
                        <h1 class="framer-text framer-styles-preset-3nqyhf" data-styles-preset="YAP816Y5n"
                            style="text-align: center;">Place your toolkit</h1></div>
                    <div class="framer-15ytn4q" data-framer-component-type="RichTextContainer"
                         style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                        <h3 class="framer-text framer-styles-preset-12lj5ox" data-styles-preset="YckFIlg3V">There are 3
                            main reasons to put your advertising toolkit&nbsp;on Playgama</h3></div>
                    <div class="framer-e5gb3q">
                        <div class="framer-l1ixc">
                            <div class="framer-1avjrl2" data-framer-component-type="RichTextContainer"
                                 style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                <h2 class="framer-text framer-styles-preset-1wml6uu" data-styles-preset="fVxnimdqP">
                                    Millions of players</h2></div>
                            <div class="framer-z2h4we" data-framer-component-type="RichTextContainer"
                                 style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                <p class="framer-text framer-styles-preset-dhi450" data-styles-preset="rGkGsoUve">A huge
                                    number of users visit Playgama every day in search of new interesting games and
                                    entertainment</p></div>
                        </div>
                        <div class="framer-3c5ljy"></div>
                        <div class="framer-1p4m2pu">
                            <div class="framer-yh2v9a" data-framer-component-type="RichTextContainer"
                                 style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                <h2 class="framer-text framer-styles-preset-1wml6uu" data-styles-preset="fVxnimdqP">High
                                    clickability</h2></div>
                            <div class="framer-1apgs7s" data-framer-component-type="RichTextContainer"
                                 style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                <p class="framer-text framer-styles-preset-dhi450" data-styles-preset="rGkGsoUve">
                                    Advertising blocks are placed in prominent places on game pages and catalogs,
                                    ensuring high clickability and audience interest</p></div>
                        </div>
                        <div class="framer-byfrt0"></div>
                        <div class="framer-wqnjn1">
                            <div class="framer-194w6vl" data-framer-component-type="RichTextContainer"
                                 style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                <h2 class="framer-text framer-styles-preset-1wml6uu" data-styles-preset="fVxnimdqP">
                                    Variety!</h2></div>
                            <div class="framer-17t6fie" data-framer-component-type="RichTextContainer"
                                 style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                <p class="framer-text framer-styles-preset-dhi450" data-styles-preset="rGkGsoUve">We
                                    attract users of all ages and social groups, as well as income levels. Our geography
                                    is the whole world. Therefore, you can maximize not only the quantity but also the
                                    quality of the twisted advertisement on our sites</p></div>
                        </div>
                    </div>
                    <div class="framer-i7qzc3-container">
                        <div class="framer-HYSVJ framer-nW5Mn framer-GDBVd framer-v-nkeur0" style="display: contents;">
                            <a class="framer-nkeur0 framer-1ch50es" data-framer-name="Basic Green" data-highlight="true"
                               href="./"
                               @click="preventedOpenForm"
                               style="backdrop-filter: none; background-color: var(--token-48d3b6c8-3544-4d0e-8305-6db8346b8991,rgb(255,221,0)); border-radius: 40px; box-shadow: none;">
                                <div class="framer-unrxkd" data-framer-component-type="RichTextContainer"
                                     style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                    <h2 class="framer-text framer-styles-preset-1wml6uu" data-styles-preset="fVxnimdqP"
                                        style="color: rgb(0, 0, 0);">Join Playgama</h2></div>
                            </a></div>
                    </div>
                </div>
                <div class="framer-1rc2smq">
                    <div class="ssr-variant hidden-39w2c5">
                        <div class="framer-10uc1e9" data-framer-component-type="RichTextContainer"
                             style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: rotate(2deg);">
                            <h1 class="framer-text framer-styles-preset-3nqyhf" data-styles-preset="YAP816Y5n"
                                style="text-align: center;">Formats we&nbsp;have TBD</h1></div>
                    </div>
                    <div class="ssr-variant hidden-2cawnp hidden-43cm73">
                        <div class="framer-10uc1e9" data-framer-component-type="RichTextContainer"
                             style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: rotate(2deg);">
                            <h1 class="framer-text framer-styles-preset-3nqyhf" data-styles-preset="YAP816Y5n"
                                style="text-align: center;">Formats <br class="framer-text">we&nbsp;have TBD</h1></div>
                    </div>
                    <div class="framer-ljnury-container">
                        <div class="framer-HYSVJ framer-nW5Mn framer-GDBVd framer-v-1fbhlu" style="display: contents;">
                            <a class="framer-nkeur0 framer-1ch50es" data-framer-name="Basic Blue" data-highlight="true"
                               href="./"
                               @click="preventedOpenForm"
                               style="backdrop-filter: none; background-color: var(--token-48d3b6c8-3544-4d0e-8305-6db8346b8991,rgb(255,221,0)); border-radius: 40px; box-shadow: none;">
                                <div class="framer-unrxkd" data-framer-component-type="RichTextContainer"
                                     style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                    <h2 class="framer-text framer-styles-preset-1wml6uu" data-styles-preset="fVxnimdqP"
                                        style="color: rgb(0, 0, 0);">Join Playgama</h2></div>
                            </a></div>
                    </div>
                </div>
                <div class="framer-1s58l9e">
                    <div class="framer-1dck6q7" data-framer-component-type="RichTextContainer"
                         style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: rotate(2deg);">
                        <h1 class="framer-text framer-styles-preset-3nqyhf" data-styles-preset="YAP816Y5n"
                            style="text-align: center;">Games</h1></div>
                    <div class="framer-16vvfyb" data-framer-component-type="RichTextContainer"
                         style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                        <h3 class="framer-text framer-styles-preset-12lj5ox" data-styles-preset="YckFIlg3V">The web
                            browser will always be the coolest place on the block to play games. It‘s an open medium
                            that allows developers to gain visibility and earn their fair share. We want to keep it that
                            way.</h3></div>
                    <LandingGames />
                    <div class="framer-rmn2gg-container">
                        <div class="framer-HYSVJ framer-nW5Mn framer-GDBVd framer-v-1fbhlu" style="display: contents;">
                            <a class="framer-nkeur0 framer-1ch50es" data-framer-name="Basic Blue" data-highlight="true"
                               href="./"
                               @click="preventedOpenForm"
                               style="backdrop-filter: none; background-color: var(--token-48d3b6c8-3544-4d0e-8305-6db8346b8991,rgb(255,221,0)); border-radius: 40px; box-shadow: none;">
                                <div class="framer-unrxkd" data-framer-component-type="RichTextContainer"
                                     style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                    <h2 class="framer-text framer-styles-preset-1wml6uu" data-styles-preset="fVxnimdqP"
                                        style="color: rgb(0, 0, 0);">Join Playgama</h2></div>
                            </a></div>
                    </div>
                </div>
                <div class="framer-16qv6dm">
                    <div class="ssr-variant hidden-43cm73">
                        <div class="framer-1oc76ry" data-framer-name="image">
                            <div data-framer-background-image-wrapper="true"
                                 style="position: absolute; border-radius: inherit; inset: 0px;"><img
                                     src="/landing/SA3xQCiwPoM1UEmAAmuLqT7u87M.webp?scale-down-to=512" alt=""
                                     sizes="(min-width: 1200px) max(233.0508px, 247.5px), (min-width: 750px) and (max-width: 1199px) max(233.0508px, 247.5px), (max-width: 749px) max(167.7966px, 220px)"
                                     loading="lazy" data-framer-original-sizes="247.5px"
                                     style="display: block; width: 100%; height: 100%; border-radius: inherit; object-position: center center; object-fit: cover; image-rendering: auto;">
                            </div>
                        </div>
                    </div>
                    <div class="framer-1oxewhr" data-framer-component-type="RichTextContainer"
                         style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: rotate(2deg);">
                        <h1 class="framer-text framer-styles-preset-3nqyhf" data-styles-preset="YAP816Y5n"
                            style="text-align: center;">Questions? Just drop us a&nbsp;line </h1></div>
                    <div class="framer-s35cv0-container">
                        <div class="framer-HYSVJ framer-nW5Mn framer-GDBVd framer-v-1fbhlu" style="display: contents;">
                            <a class="framer-nkeur0 framer-1ch50es" data-framer-name="Basic Blue" data-highlight="true"
                               href="./"
                               @click="preventedOpenForm"
                               style="backdrop-filter: none; background-color: var(--token-48d3b6c8-3544-4d0e-8305-6db8346b8991,rgb(255,221,0)); border-radius: 40px; box-shadow: none;">
                                <div class="framer-unrxkd" data-framer-component-type="RichTextContainer"
                                     style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                    <h2 class="framer-text framer-styles-preset-1wml6uu" data-styles-preset="fVxnimdqP"
                                        style="color: rgb(0, 0, 0);">Join Playgama</h2></div>
                            </a></div>
                    </div>
                </div>
                <div class="framer-1bjkca5">
                    <div class="framer-zfoo55-container">
                        <div class="framer-SmHod framer-v-pfebnk" style="display: contents;">
                            <div class="framer-pfebnk" data-framer-name="Variant 1" style="width: 100%;">
                                <div class="framer-1xvbwdp" data-framer-component-type="RichTextContainer"
                                     style="outline: none; display: flex; flex-direction: column; justify-content: flex-start; flex-shrink: 0; transform: none;">
                                    <p class="framer-text" style="text-align: center; color: var(--extracted-r6o4lv);">
                                        LeadToChanges L.L.C-FZ<br>The Meydan Hotel, Grandstand, 6th floor, Meydan Road, Nad Al Sheba<br>Dubai, U.A.E</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="framer-1qo6i5s" data-framer-name="Glow"></div>
            <div class="ssr-variant hidden-43cm73">
                <div class="framer-1xs7hwa-container">
                    <div class="ssr-variant hidden-39w2c5">
                        <div class="framer-XR1hm framer-GDBVd framer-IRinQ framer-v-1o1l5f5" style="display: contents;">
                            <LandingHeader :type="type" :on-open-submit-form="openForm"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="overlay"></div>
        <LandingForm
            :type="type"
            :open="isSubmitFormOpen"
            :onClose="closeForm"
        />
    </div>

</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { useHead } from '@unhead/vue'
import LandingGames from '../components/LandingGames/LandingGames.vue'
import LandingForm from '../components/LandingForm/LandingForm.vue'
import LandingHeader from '../components/LandingHeader/LandingHeader.vue'

const pageMeta = {
    title: 'Advertise with Us | Playgama 📈',
    meta: [
        {
            name: 'description',
            content: 'Reach a targeted audience of gamers by advertising with Playgama. Boost your brand visibility and engagement.',
        },
    ],
}
useHead(pageMeta)

const isSubmitFormOpen = ref(false)
const type = 'adv'

const openForm = () => {
    isSubmitFormOpen.value = true
}

const closeForm = () => {
    isSubmitFormOpen.value = false
}

const preventedOpenForm = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    openForm()
}

</script>
<style>
@import "./styles.css";
</style>
