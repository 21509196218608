import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { CategoriesType, SimplifiedGame } from '@/types'
import { getGamesByCategories } from '@/utils/requests/games'
import { PlatformType, PaginationType, CategoryDataType } from '@/types'
import { useAppStore } from '@/store/app-store'
import { categoriesTexts, categoriesFullNamesTranslation } from '@/utils/translations/categories'

// divisible by 2, 3, 4, 6 to make a nice grid with big cards
const PAGE_SIZE = 48

type SetCategoryParams = {
    categoryName: string,
    page: number
}

type UpdateListRequestParams = {
    categoryName: CategoriesType,
    platform: PlatformType,
    pagination: PaginationType,
}

type Category = {
    id: CategoriesType,
    name: string,
    metaTitle: string,
    metaDescription: string,
    description: string,
    seoText: string,
    total: number,
    totalPages: number,
}

function updateList({ categoryName, platform, pagination }: UpdateListRequestParams): Promise<CategoryDataType> {
    return getGamesByCategories({
        categoriesIds: [categoryName],
        platform,
        pagination,
    })
}

export const useCategoryPageStore = defineStore('categoryPage', () => {
    const pages = ref<(SimplifiedGame[] | null)[]>([])
    const pageSize = ref(PAGE_SIZE)
    const category = ref<Category | null>(null)

    const appStore = useAppStore()

    async function setCategory(params: SetCategoryParams): Promise<void> {
        const pageIndex = params.page - 1
        if (
            category.value?.id === params.categoryName
            && pages.value[pageIndex] !== undefined
        ) {
            // не грузим данные если они уже есть и именно такие
            return
        }

        // this data is available only on second time this method is called
        // TODO load category meta separately
        if (typeof category.value?.totalPages === 'number') {
            if (params.page > category.value.totalPages) {
                return
            }
        }

        if (category.value?.id !== params.categoryName) {
            pages.value = []
            category.value = null
        }

        // TODO dangerous on server, when request is ?page=10000000000
        pages.value[pageIndex] = null

        const { categories: [fetchedCategory] } = await updateList({
            categoryName: params.categoryName as CategoriesType,
            pagination: {
                page: params.page,
                pageSize: pageSize.value,
            },
            platform: appStore.platform,
        })
        if (!fetchedCategory || fetchedCategory.games.length === 0) {
            // eslint-disable-next-line no-throw-literal
            throw 404
        }
        pages.value[pageIndex] = fetchedCategory.games
        category.value = {
            id: fetchedCategory.id,
            name: categoriesFullNamesTranslation.en[fetchedCategory.id], // en locale temp
            metaTitle: categoriesTexts[fetchedCategory.id].shortTitle, // temp
            metaDescription: categoriesTexts[fetchedCategory.id].shortDescription, // temp
            description: categoriesTexts[fetchedCategory.id].description, // temp
            seoText: categoriesTexts[fetchedCategory.id].seoText, // temp
            total: fetchedCategory.totalCount,
            totalPages: Math.ceil(fetchedCategory.totalCount / pageSize.value),
        }
        pages.value = pages.value.slice(0, category.value.totalPages)
    }

    return {
        pages,
        pageSize,
        category,
        setCategory,
    }
})
