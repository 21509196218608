<template>
    <div :class="$style.container">
        <div :class="$style.grid">
            <div :class="$style.right">
                <div :class="$style.desktop_header">
                    <DesktopHeader />
                </div>
                <div :class="$style.mobile_header">
                    <MobileHeader />
                </div>
                <slot />
                <Footer />
            </div>
            <!-- keeping it at the bottom of the html for better SEO -->
            <div :class="$style.left">
                <LeftSide />
            </div>
        </div>
        <ModalCategories/>
        <ModalInvite/>
    </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import LeftSide from '@/components_new/LeftSide/LeftSide.vue'
import Footer from '@/components_new/Footer.vue'
import DesktopHeader from '@/components_new/DesktopHeader.vue'
import MobileHeader from '@/components_new/MobileHeader.vue'
import ModalCategories from '@/components_new/ModalCategories/ModalCategories.vue'
import { useLayoutStore } from '@/store/layout-store'
import { watch } from 'vue'
import ModalInvite from '@/components_new/ModalItems/components/ModalInvite.vue'

const { fullScreen } = storeToRefs(useLayoutStore())

watch(fullScreen, (value) => {
    if (value) {
        // ios safari has bug with bottom navigation pannel.
        // Sometimes it causes element with "position: absolute; top: 0"
        // to be shifted up after applying "overflow: hidden" on body.
        // Solution: set random positive scroll after repaint
        requestAnimationFrame(() => {
            window.scrollTo(0, 1)
        })
    }
    document.documentElement.classList.toggle('fullscreen_css', value)
})
</script>

<style module>
:global(html.fullscreen_css), :global(html:has(dialog[open])) {
    overflow: hidden;
    position: relative;
    height: 100%;
    overscroll-behavior: none;
}

:global(html.fullscreen_css body), :global(html:has(dialog[open]) body) {
    overflow: hidden;
    position: relative;
    height: 100%;
    background: #000;
}

.container {
    min-height: 100%;
    background-color: #000;
    --global-padding-horizontal: 12px;
    padding: 0 var(--global-padding-horizontal);
}

.grid {
    display: grid;
    gap: 24px;
}

.left {
    grid-column: 1;
    grid-row: 1;
    /* height: min-content; */
    max-height: 100vh;
    position: sticky;
    top: 0;
    overflow: auto;
    margin-bottom: 10px;
    scrollbar-width: none;
}

.right {
    grid-column: 2;
}

.desktop_header {
    height: 88px;
    display: block;
}

.mobile_header {
    display: block;
    margin: 0 calc(var(--global-padding-horizontal) * -1) var(--global-page-top-padding);
}

@media (--mobile), (--tablet-plus) {
    .container {
        --global-wide-scroller-padding-left: 12px;
        --global-wide-scroller-padding-right: 12px;
        --global-header-pannel-height: 56px;
        --global-page-top-padding: 12px;
    }

    .grid {
        grid-template-columns: minmax(0, 1fr);
    }

    .left {
        display: none;
    }

    .right {
        grid-column: 1;
    }

    .desktop_header {
        display: none;
    }

    .mobile_header {
        display: block;
    }
}

@media (--desktop-small-plus) {
    .container {
        --global-wide-scroller-padding-left: 0px;
        --global-wide-scroller-padding-right: 12px;
        --global-header-pannel-height: 0px;
    }

    .grid {
        /* left column is 240px until screen width reaches 1400px, then it is starts growing proportionally */
        grid-template-columns: max(240px, calc(240/1400*100vw)) minmax(0, 1fr);
    }

    .left {
        display: block;
    }

    .right {
        grid-column: 2;
    }

    .desktop_header {
        display: block;
    }

    .mobile_header {
        display: none;
    }
}
</style>
