<template>
    <Layout>
        <template #leftSide>
            <LeftSide
                :highlightCategories="menuHighlightCategoriesList"
                :categories="menuCategoriesList"
            />
        </template>
        <div class="markdown-wrapper">
            <div v-html="markup" />
        </div>
        <template #rightSide>
            <RightSide :recentlyGames="recommendedGames"
            />
        </template>
    </Layout>
</template>
<script lang="ts" setup>
import markdownit from 'markdown-it'

import { useRoute } from 'vue-router'
import Layout from '@/layouts/ThreeColumnsLayout.vue'
import LeftSide from '@/components/LeftSide.vue'
import { storeToRefs } from 'pinia'
import { useCategoriesStore } from '@/store/categories-store'
import { onMounted, onServerPrefetch } from 'vue'
import { useHead } from '@unhead/vue'
import RightSide from '@/components/RightSide.vue'
import privacy from './confidential/Privacy Policy.md'
import license from './license/License agreement.md'
import termsofuse from './termsofuse/Terms of Use.md'
import partners from './partners-api/partners-api.md'

const route = useRoute()
const categoriesStore = useCategoriesStore()
const {
    contentCategoriesList,
    menuCategoriesList,
    menuHighlightCategoriesList,
    recommendedGames,
} = storeToRefs(categoriesStore)

let src = ''
let pageMeta = {}

switch (route.name) {
case 'confidential':
    src = privacy
    pageMeta = {
        title: 'Privacy Policy | Playgama 🔒',
        meta: [
            {
                name: 'description',
                content: 'Learn about Playgama\'s commitment to protecting your privacy and personal information. Read our privacy policy.',
            },
        ],
    }
    break
case 'termsofuse':
    src = termsofuse
    pageMeta = {
        title: 'Terms of Use | Playgama ⚖️',
        meta: [
            {
                name: 'description',
                content: 'Review the terms and conditions for using Playgama\'s services. Understand your rights and responsibilities.',
            },
        ],
    }
    break
case 'license':
    src = license
    pageMeta = {
        title: 'License Agreement | Playgama 📄',
        meta: [
            {
                name: 'description',
                content: 'Read the license agreement for using Playgama\'s games and services. Ensure compliance and understanding.',
            },
        ],
    }
    break
case 'partners-api':
    src = partners
    break
default:
    break
}

useHead(pageMeta)

const md = markdownit({
    html: true,
})
const markup = md.render(src)

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

onMounted(async () => {
    // Prevents the request from being made twice if the data is already in the store
    if (contentCategoriesList.value.length) {
        return
    }
    await categoriesStore.setMainData()
})
</script>
<style>
.markdown-wrapper a, .markdown-wrapper a:hover, .markdown-wrapper a:visited {
    color: var(--accent-purple)
}

.markdown-wrapper {
    max-width: 560px;
    margin: 0;
}

.markdown-wrapper p {
    margin-bottom: var(--gap-large);
}

.markdown-wrapper li p {
    margin-bottom: var(--gap-small);
}

.markdown-wrapper blockquote {
    background-color: rgba(255,255,255,0.1);
    margin: 0 0 var(--gap-large) 0;
    padding: var(--gap-large);
    border-radius: var(--radius-large);
}
.markdown-wrapper blockquote p{
    margin: 0;
}
.markdown-wrapper code {
    background-color: rgba(255,255,255,0.1);
    font-family: monospace;
    font-weight: var(--font-weight-bold);
    display: inline-block;
    padding: 0 5px;
}

.markdown-wrapper pre code {
    padding: var(--gap-large);
    display: block;
    border-radius: var(--radius-large);
    white-space: pre-wrap;
    margin-bottom: var(--gap-large);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
}

.markdown-wrapper h1 {
    padding-bottom: var(--gap-regular);
    margin-bottom: var(--gap-large);
    border-bottom: 1px solid var(--white-25);
}
.markdown-wrapper h2,
.markdown-wrapper h3 {
    margin-bottom: var(--gap-large);
}

.markdown-wrapper table {
    width: 100%;
    padding: var(--gap-large);
    border-radius: var(--radius-large);
    background-color: rgba(255,255,255,0.1);
    margin-bottom: var(--gap-large);
}
.markdown-wrapper th {
    text-align: left;
}

.markdown-wrapper tr td{
    border-bottom: 1px solid var(--white-25);
    padding: var(--gap-small) var(--gap-small) var(--gap-small) 0;
}

.markdown-wrapper tr td:last-child{
    padding-right: 0;
}

.markdown-wrapper img {
    max-width:100%;
}

.markdown-wrapper ul {
    background-color: rgba(255,255,255,0.1);
    margin: var(--gap-medium) 0 var(--gap-large) 0;
    padding: var(--gap-large);
    border-radius: var(--radius-large);
}

.markdown-wrapper ul li{
    padding: var(--gap-small) 0;
    border-bottom: 1px solid var(--white-25);
}

.markdown-wrapper table ul {
    word-break: break-word;
}
</style>
