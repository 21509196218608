<template>
    <PromoBanner v-if="!isAuthorized && isUserInfoLoaded" :image="{src: FennecCoinsImg, alt: t('sign_in_alt')}" :description="t('sign_in_desc')" direction="column" icon="fennek_coin"
                 :title="t('sign_in_title')">
        <template #default="{ handleClick }">
            <Button size="l" color="golden" @click="handleClick('sign_in')">
                {{ t('sign_in_btn') }}
            </Button>
        </template>
    </PromoBanner>
</template>
<script setup lang="ts">

import Button from '@/components_new/Button.vue'
import FennecCoinsImg from '@/components_new/PromoItems/images/fennek-coin.png'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'
import { promoItemsTranslation } from '@/components_new/PromoItems/utils'

const { t } = useI18n({
    messages: {
        en: {
            ...promoItemsTranslation.en,
        },
    },
})

const userStore = useUserStore()
const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)
</script>
