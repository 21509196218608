export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "messageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
        "messagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide as much information as possible"])},
        "emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Email"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
      }
    }
  })
}
