type EventListenerArgs = [
    type: string,
    callback: (event: Event & { detail?: unknown }) => any,
]
export default class EventBus {
    private readonly elem: HTMLElement

    constructor() {
        this.elem = document.createElement('b')
    }

    addEventListener(...args: EventListenerArgs) {
        this.elem.addEventListener(...args)
    }

    removeEventListener(...args: EventListenerArgs) {
        this.elem.removeEventListener(...args)
    }

    dispatch(event: string, payload?: unknown) {
        this.elem.dispatchEvent(new CustomEvent<unknown>(event, { detail: payload }))
    }
}
