<template>
    <iframe
        v-if="game"
        ref="iframeRef"
        :class="$style.iframe"
        :title="game.title"
        :src="gameSrc"
    />
    <ErrorContent
        v-else-if="pageStatus !== 200"
        :random-game="null"
        :type="pageStatus"
    />
</template>
<script lang="ts" setup>
import {
    onBeforeUnmount, onMounted, onServerPrefetch, ref,
} from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useHead } from '@unhead/vue'
import { PageStatus, useAppStore } from '@/store/app-store'
import { MainFrameService } from '@/modules/main-frame'
import { useAdsStore } from '@/store/ads-store'
import { sendEvent } from '@/utils/analytics'
import { useUserStore } from '@/store/user-store/user-store'
import ErrorContent from '@/components_new/ErrorContent/ErrorContent.vue'
import { generateHead } from './units'
import { useGamePageStore } from './game-store'

const CLID_PARAM_NAME = 'clid'
const PAYLOAD_PARAM_NAME = 'payload'

const gamePageStore = useGamePageStore()
const appStore = useAppStore()
const { pageStatus } = storeToRefs(appStore)
const { game } = storeToRefs(gamePageStore)
const userStore = useUserStore()

useHead(generateHead({ game }))

const route = useRoute()
const initialized = ref(false)
const gameSrc = ref('')
const iframeRef = ref<HTMLIFrameElement>(null)

const clid = route.query[CLID_PARAM_NAME] as string || undefined

const mainFrameService = ref<MainFrameService>()
const adsStore = useAdsStore()

async function updateData(routeParams: string) {
    if (!game || !game.value || game.value.hru !== routeParams) {
        gamePageStore.resetGameData()
        await gamePageStore.setGame(routeParams)
    }
}

onMounted(async () => {
    if (appStore.pageStatus !== 200) {
        return
    }

    if (!game.value) {
        // dev-mode
        await updateData(route.params.game as string)
    }

    if (!adsStore.adMediator) {
        console.error('Error: No ad service was passed to the main frame')
        return
    }

    const payload = route.query[PAYLOAD_PARAM_NAME] || ''

    const url = new URL(game.value.game_url)

    if (clid) {
        url.searchParams.set('clid', clid as string)
    }
    if (payload) {
        url.searchParams.set('payload', payload as string)
    }

    sendEvent({
        eventName: 'game_opened',
        userId: userStore.user ? userStore.user._id : undefined,
        clid: appStore.clid,
        pageName: route.name as string || undefined,
        gameHru: game.value?.hru,
    })

    gameSrc.value = url.href

    initialized.value = true

    mainFrameService.value = new MainFrameService({
        iframeElement: iframeRef.value,
        clid,
        adMediator: adsStore.adMediator,
        logger: window.dataLayer,
    })
})

onBeforeUnmount(() => {
    mainFrameService.value?.destroy()
})

onServerPrefetch(async () => {
    try {
        const serverGame = await gamePageStore.setGame(route.params.game as string)
        if (serverGame.id === route.params.game && route.params.game !== serverGame.hru) {
            const search = route.fullPath.replace(/^[^?]+/i, '')
            appStore.setPageStatus(301)
            appStore.setTargetURL(`/export/game/${serverGame.hru}${search}`)
        }
    } catch (e) {
        // no throwing here, we handle errors manually
        if (typeof e === 'number') {
            appStore.setPageStatus(e as PageStatus)
        } else {
            appStore.setPageStatus(500)
        }
    }
})

</script>
<style module>
.iframe {
    height: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 0;
    width: 0;
}
</style>
