export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertisers"])},
        "publishers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishers"])},
        "developers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developers"])},
        "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
        "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2024 Playgama"])}
      }
    }
  })
}
