<template>
    <component
        :is="props.is"
        :class="[$style.cardContainer, props.responsive && $style.responsive, props.appearance === 'big' && $style.bigCard]"
    >
        <img
            v-if="props.src"
            :alt="props.alt"
            :src="props.src"
            :srcset="srcset"
            :class="$style.img"
            itemprop="image"
            loading="lazy"
            width="1"
            height="1"
        />
        <slot></slot>
    </component>
</template>

<script setup lang="ts">
import {
    computed,
} from 'vue'
import { RouterLink } from 'vue-router'

interface CardContainerProps {
  is?: 'div' | RouterLink,
  appearance: 'big'|'medium',
  src?: string,
  alt?: string,
  srcsetOn?: boolean,
  responsive?: boolean,
}

const props = withDefaults(defineProps<CardContainerProps>(), {
    is: 'div',
    srcsetOn: true,
})

function transformSrcSet(input?: string) {
    if (!input || !props.srcsetOn) return ''
    switch (props.appearance) {
    case 'big':
        return input
    case 'medium':
    default:
        return `${input.replace('enlarged', 'medium')},
            ${input} 2x`
    }
}

const srcset = computed(() => transformSrcSet(props.src))

</script>

<style module>

.cardContainer {
  display: block;
  width: 220px;
  height: 132px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  background-color: #17171A;
}
.responsive {
  width: 100%;
  aspect-ratio: 220/132;
  height: auto;
}

.bigCard.responsive {
  /* same as normal, but with additional 8px gaps */
  aspect-ratio: calc(220 + var(--cards-gap))/calc(132 + var(--cards-gap));
}

.img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
