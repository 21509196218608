import type { AdType } from '@/types'
import { RewardedBanner } from '@/modules/adv/rewarded-banner'
import { Banner } from '@/modules/adv/banner'
import {
    AdvService, AdProviderStatus, PageAdOptions,
} from '../adv'

import { formats, type YaPlatformType, type FormatType } from './formats'

function destroyBlock(format: FormatType) {
    // @ts-ignore
    Ya.Context.AdvManager.destroy(format)
}

const YAN_SOURCE = 'https://yandex.ru/ads/system/context.js'

export class YandexAdvertisingNetwork implements AdvService {
    private formats: typeof formats

    private platform: YaPlatformType = 'desktop'

    private ya: (() => void)[]

    private destroyBlock: (format: FormatType) => void

    readonly serviceStatus: Promise<AdProviderStatus>

    constructor() {
        this.formats = formats
        this.destroyBlock = destroyBlock
        // @ts-ignore
        window.yaContextCb = window.yaContextCb || []
        // @ts-ignore
        this.ya = window.yaContextCb

        this.serviceStatus = new Promise((res, rej) => {
            const script = document.createElement('script')
            script.src = YAN_SOURCE

            script.addEventListener('error', () => {
                rej(AdProviderStatus.offline)
            })
            document.body.appendChild(script)
            window.yaContextCb.push(() => {
                // @ts-ignore
                this.platform = window.Ya.Context.AdvManager.getPlatform() as YaPlatformType
                res(AdProviderStatus.online)
            })
        })
    }

    prepareOutOfPageAd(type: AdType): Promise<RewardedBanner> {
        return new Promise((res, rej) => {
            const banner = new RewardedBanner({})
            const format = this.formats[type][this.platform]
            if (format) {
                res(banner)
                setTimeout(() => {
                    banner.triggerReady(() => this.showOutOfPageBanner(banner, format))
                }, 50)
            } else {
                rej()
            }
        })
    }

    // eslint-disable-next-line class-methods-use-this
    requestPageAd(options: PageAdOptions):Promise<Banner> {
        return new Promise((res, rej) => {
            const format = this.formats[options.type][this.platform]
            if (!format) {
                rej()
                return
            }
            window.yaContextCb.push(() => {
                const banner = new Banner({
                    destroy: () => {
                        this.destroyBlock(format)
                        // if (refreshInterval) {
                        //     clearInterval(refreshInterval)
                        // }
                    },
                })
                // @ts-ignore
                Ya.Context.AdvManager.render({
                    ...format,
                    renderTo: options.el,
                    onClose: () => {
                        setTimeout(() => {
                            // making sure "Rewarded" callback is triggered before close
                            banner.triggerClosed()
                            this.destroyBlock(format)
                        }, 100)
                    },
                    onError: (e: unknown) => {
                        console.error('yan ads:', e)
                        banner.triggerEmpty()
                        this.destroyBlock(format)
                    },
                    onRender: () => {
                        banner.triggerViewable()
                        banner.triggerRendered()
                    },
                })
                res(banner)
            })
        })
    }

    private showOutOfPageBanner(banner: RewardedBanner, format: FormatType) {
        window.yaContextCb.push(() => {
            const rewardedCallback: {onRewarded?: () => void} = {}
            if (format!.type === 'rewarded') {
                rewardedCallback.onRewarded = () => {
                    banner.triggerRewarded({})
                }
            }
            // @ts-ignore
            Ya.Context.AdvManager.render({
                ...format,
                onClose: () => {
                    setTimeout(() => {
                        // making sure "Rewarded" callback is triggered before close
                        banner.triggerClosed()
                        this.destroyBlock(format)
                    }, 100)
                },
                onError: () => {
                    banner.triggerEmpty()
                    this.destroyBlock(format)
                },
                onRender: () => {
                    banner.triggerViewable()
                    banner.triggerRendered()
                },
                ...rewardedCallback,
            }, () => {
                banner.triggerEmpty()
                this.destroyBlock(format)
            })
        })
    }

    updateTargeting() {}
}
