import { defineStore } from 'pinia'
import { ref } from 'vue'
import { PlatformType } from '@/types'

const DEFAULT_PLATFORM = 'desktop'

export const VISITS_AFTER_LOGIN_BANNER_CLOSED_KEY = 'playgama:visits_after_login_banner_closed'
export const VISITS_BEFORE_LOGIN_BANNER_SHOWN = 5
export type ErrorStatus = 404 | 500
export type PageStatus = 200 | 301 | 302 | ErrorStatus
const HOST_URL = process.env.VUE_APP_HOST_URL as string

export const useAppStore = defineStore('app', () => {
    const pageStatus = ref(200)
    const targetURL = ref('')
    const clid = ref('direct')
    const platform = ref<PlatformType>(DEFAULT_PLATFORM)

    const isAuthBannerOpen = ref<boolean>(false)

    function setPageStatus(status: PageStatus) {
        pageStatus.value = status
    }

    function setTargetURL(target: string, origin: string = HOST_URL) {
        targetURL.value = `${origin}${target}`
    }

    function setPlatform(plt: PlatformType) {
        platform.value = plt
    }

    function setClid(value: string) {
        clid.value = value
    }

    function initAuthBannerOpen() {
        const visitsAfterBannerClosedLS = window.localStorage.getItem(VISITS_AFTER_LOGIN_BANNER_CLOSED_KEY)
        if (!visitsAfterBannerClosedLS) {
            isAuthBannerOpen.value = true
        } else {
            const visitsNum = Number(visitsAfterBannerClosedLS)
            if (!Number.isNaN(visitsNum) && visitsNum >= 0 && visitsNum < VISITS_BEFORE_LOGIN_BANNER_SHOWN) {
                window.localStorage.setItem(VISITS_AFTER_LOGIN_BANNER_CLOSED_KEY, (visitsNum + 1).toString())
            } else {
                window.localStorage.removeItem(VISITS_AFTER_LOGIN_BANNER_CLOSED_KEY)
                isAuthBannerOpen.value = true
            }
        }
    }

    function $reset() {
        pageStatus.value = 200
        targetURL.value = ''
        platform.value = DEFAULT_PLATFORM
    }

    return {
        setPageStatus,
        setTargetURL,
        setClid,
        pageStatus,
        targetURL,
        clid,
        platform,
        isAuthBannerOpen,
        setPlatform,
        initAuthBannerOpen,
        $reset,
    }
})
