export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "rotate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotate phone"])},
        "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The game supports only ", _interpolate(_named("orientation")), " orientation"])}
      }
    }
  })
}
