import { Banner } from './banner'

export class RewardedBanner extends Banner {
    private makeVisible: (() => void) | undefined

    triggerReady(callback: () => void) {
        this.makeVisible = callback
        this.dispatch('ready')
    }

    show() {
        if (this.makeVisible) {
            this.makeVisible()
        } else {
            throw new Error('The banner is not ready')
        }
    }
}
