import type { AdType } from '@/types'
import { PageAdType } from '../adv'

export type YaPlatformType = 'desktop' | 'touch'

export type FormatType = {
    blockId: string
    type?: AdType | 'fullscreen',
    platform: YaPlatformType,
} | null

export const formats: Record<AdType | PageAdType, Partial<Record<YaPlatformType, FormatType>>> = {
    rewarded: {
        desktop: {
            blockId: 'R-A-4580605-1',
            type: 'rewarded',
            platform: 'desktop',
        },
        touch: {
            blockId: 'R-A-4580605-2',
            type: 'rewarded',
            platform: 'touch',
        },
    },
    interstitial: {
        desktop: {
            blockId: 'R-A-4580605-4',
            type: 'fullscreen',
            platform: 'desktop',
        },
        touch: {
            blockId: 'R-A-4580605-3',
            type: 'fullscreen',
            platform: 'touch',
        },
    },
    sidebar_desktop: {
        desktop: {
            blockId: 'R-A-4580605-5',
            platform: 'desktop',
        },
    },
    sticky_portrait: {
        touch: {
            blockId: '',
            platform: 'touch',
        },
    },
}
