export const category2icon = {
    action: 'action',
    adventure: 'adventure',
    all_games: 'all_games',
    applications: 'applications',
    arcade: 'arcade',
    baby: 'baby',
    balloons: 'balloons', // typo in the category data
    bejeweled: 'bejeweled',
    boys: 'boys',
    cards: 'cards',
    casino: 'casino',
    clicker: 'clicker',
    complete_reg: 'complete_reg',
    cook: 'cook',
    economic: 'economic',
    educational: 'educational',
    farming: 'farming',
    girls: 'girls',
    horrors: 'horrors',
    hypercasual: 'hypercasual',
    imitations: 'imitations',
    io: 'io',
    kids: 'kids',
    match3: 'match3',
    midcore: 'midcore',
    multiplayer: 'multiplayer',
    new: 'new',
    popular: 'popular',
    purchase: 'purchase',
    puzzle: 'puzzle',
    quiz: 'quiz',
    racing: 'racing',
    random: 'random',
    recommended: 'recommended',
    role: 'role',
    shooting: 'shooting',
    simulator: 'simulator',
    soccer: 'soccer',
    social: 'social',
    special: 'special',
    sports: 'sports',
    stickman: 'stickman',
    strategy: 'strategy',
    tabletop: 'tabletop',
    tests: 'tests',
    three_d: 'three_d',
    trending_now: 'trending_now',
    '2_player': 'two_player',
}
