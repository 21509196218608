import type { RouteLocationNormalizedLoaded } from 'vue-router'
import {
    GameEvent,
    GameProviders,
    type EventForLoggersType,
    type AdType,
} from '@/types'
import { RewardedBanner } from '@/modules/adv/rewarded-banner'
import { DebugAdsProvider } from '@/modules/debug-provider'
import { GameDistributionConnector } from '../gd-connector/gd-connector'
import { GoogleAdsService } from '../google-ads'
import { YandexAdvertisingNetwork } from '../yandex-advertising-network/index'
import {
    AdProviderStatus, AdvAction, AdvService as AdvProvider, AdvServiceConfig, PageAdOptions,
} from '../adv'
import EventBus from '../event-bus'
import { type AdProviderType, config } from './config'

export type AdMediatorOptions = {
    configKey: keyof typeof config,
    targeting?: Record<string, string | string[]>,
    logEvent: (event: EventForLoggersType) => void,
    route?: RouteLocationNormalizedLoaded,
}

type AdStatus = 'start' | 'error' | 'empty' | 'done' | 'open' | 'show' | 'rewarded' | 'close'

type AdResponsePayload = {
    status: AdStatus,
    error?: string,
}

const TYPE_SETTINGS: Record<AdvAction, AdType> = {
    [AdvAction.preloadRewarded]: 'rewarded',
    [AdvAction.showRewarded]: 'rewarded',
    [AdvAction.preloadInterstitial]: 'interstitial',
    [AdvAction.showInterstitial]: 'interstitial',
}

function getProvider(adProvider: GameProviders) {
    switch (adProvider) {
    case GameProviders.YANDEX_AD:
        return YandexAdvertisingNetwork
        break
    case GameProviders.GAME_DISTRIBUTION:
        return GameDistributionConnector
        break
    case GameProviders.DEBUG_PROVIDER:
        return DebugAdsProvider
        break
    case GameProviders.GOOGLE_AD:
    default:
        return GoogleAdsService
        break
    }
}

export class AdMediator {
    private isShowingFullscreenAd = false

    private config: AdProviderType

    private banners: Map<AdType, RewardedBanner>

    private adProvider: AdvProvider

    private adFallback: AdvProvider | undefined

    private originalMessages: Map<string, GameEvent>

    private readonly serviceReadyPromise: Promise<AdProviderStatus>

    private logEvent: (event: EventForLoggersType) => void

    private route?: RouteLocationNormalizedLoaded

    eventBus: EventBus

    constructor(options: AdMediatorOptions) {
        this.config = config[options.configKey]
        const Provider = getProvider(this.config.adProvider)
        this.adProvider = new Provider({ targeting: options.targeting })
        this.logEvent = options.logEvent
        this.route = options.route

        if (this.config.fallback) {
            const Fallback = getProvider(this.config.fallback)
            this.adFallback = new Fallback({ targeting: options.targeting })
        }
        this.banners = new Map()
        this.eventBus = new EventBus()
        this.originalMessages = new Map()
        this.serviceReadyPromise = new Promise((res, rej) => {
            this.adProvider.serviceStatus.then(() => {
                res(AdProviderStatus.online)
            }, () => {
                if (this.adFallback) {
                    this.adProvider = this.adFallback
                    this.adFallback = undefined
                    this.adProvider.serviceStatus.then(() => {
                        res(AdProviderStatus.online)
                    }, () => {
                        rej(AdProviderStatus.offline)
                    })
                } else {
                    rej(AdProviderStatus.offline)
                }
            })
        })
    }

    handleMessage(event: GameEvent) {
        this.originalMessages.set(event.data.id, event)

        switch (event.data.action) {
        case 'preloadInterstitial':
            this.preloadAd('interstitial', event.data.id)
            break
        case 'preloadRewarded':
            this.preloadAd('rewarded', event.data.id)
            break
        case 'showInterstitial':
            this.showAd('interstitial', event.data.id)
            break
        case 'showRewarded':
            this.showAd('rewarded', event.data.id)
            break
        default:
            this.eventBus.dispatch('adMessage', {
                originalMessageEvent: event as GameEvent,
                payload: {
                    error: `Unknown message action "${event.data.action}"`,
                },
                type: 'error',
            })
            this.originalMessages.delete(event.data.id)
        }
    }

    async preparePageAd(options: PageAdOptions) {
        try {
            await this.serviceReadyPromise
        } catch (e) {
            // сегодня без рекламы
            return Promise.reject()
        }
        const banner = await this.adProvider.requestPageAd(options)
        const adEvent: Omit<EventForLoggersType, 'action'> = {
            event: 'custom_event',
            eventName: 'ad_request',
            label: 'sticky',
            pageName: this.route?.name as string || undefined,
        }
        banner.addEventListener('empty', () => this.logEvent({
            ...adEvent,
            action: 'empty',
        }))

        banner.addEventListener('viewable', () => this.logEvent({
            ...adEvent,
            action: 'show',
        }))

        return banner
    }

    private async showAd(type: AdType, messageId: string) {
        if (this.isShowingFullscreenAd) {
            this.triggerMessage(
                {
                    status: 'error',
                    error: 'Another ad is already open',
                },
                messageId,
            )
            return
        }

        let banner = this.banners.get(type)
        if (!banner) {
            try {
                banner = await this.preloadAd(type, messageId)
                this.listenToRenderedBannerEvents(banner, messageId)
            } catch (e) {
                // Messages have been sent from the method
                this.isShowingFullscreenAd = false
                return
            }
        }
        this.isShowingFullscreenAd = true

        banner.addEventListener('empty', () => {
            // sometimes we don't know a banner is empty till show it.
            // we can't move this code to the general empty banner handler
            // because an ad may be loading while showing other ad
            this.isShowingFullscreenAd = false
            this.triggerMessage({ status: 'close' }, messageId)
        })

        // the banner is in the ready status
        banner.show()

        // we don't have to keep it anymore, listeners are setted
        this.banners.delete(type)
    }

    /**
     * @return Promise<RewardedBanner> - a ready-to-show banner.
     * If there was an error or slot was empty - rejection will be returned
     */
    private async preloadAd(type: AdType, messageId: string):Promise<RewardedBanner> {
        try {
            await this.serviceReadyPromise
        } catch (e) {
            // сегодня без рекламы
            return Promise.reject()
        }
        const message = this.originalMessages.get(messageId)
        if (!message) {
            return Promise.reject()
        }
        let banner: RewardedBanner
        this.triggerMessage({ status: 'start' }, messageId)
        this.logEvent({
            event: 'custom_event',
            eventName: 'ad_request',
            label: type,
            action: 'start',
            pageName: this.route?.name as string || undefined,
        })
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                banner = await this.adProvider.prepareOutOfPageAd(type)
            } catch (error) {
                if (!this.adFallback) {
                    this.triggerMessage({ status: 'error', error: 'Cannot show the ad' }, messageId)
                    reject()
                    this.originalMessages.delete(messageId)
                    return
                }
                try {
                    banner = await this.adFallback.prepareOutOfPageAd(type)
                } catch (fallbackError) {
                    this.triggerMessage({ status: 'error', error: 'Cannot show the ad' }, messageId)
                    reject()
                    this.originalMessages.delete(messageId)
                    return
                }
            }

            banner.addEventListener('empty', async () => {
                if (this.adFallback) {
                    let fallback:RewardedBanner
                    try {
                        fallback = await this.adFallback.prepareOutOfPageAd(type)
                    } catch (e) {
                        // errors in fallback shouldn't change empty status to error
                        this.triggerMessage({ status: 'empty' }, messageId)
                        reject()
                        this.originalMessages.delete(messageId)
                        this.isShowingFullscreenAd = false
                        return
                    }
                    fallback.addEventListener('empty', () => {
                        this.triggerMessage({ status: 'empty' }, messageId)
                        reject()
                        this.originalMessages.delete(messageId)
                        this.isShowingFullscreenAd = false
                    })

                    fallback.addEventListener('ready', () => {
                        this.triggerMessage({ status: 'done' }, messageId)
                        resolve(fallback)
                    })

                    this.banners.set(type, fallback)
                } else {
                    this.triggerMessage({ status: 'empty' }, messageId)
                    reject()
                }
            })

            banner.addEventListener('ready', () => {
                this.triggerMessage({ status: 'done' }, messageId)
                this.banners.set(type, banner)
                resolve(banner)
            })
        })
    }

    private listenToRenderedBannerEvents(banner: RewardedBanner, messageId: string) {
        const message = this.originalMessages.get(messageId)
        if (!message) {
            return
        }
        const { action } = message.data
        const type = TYPE_SETTINGS[action]

        if (type === 'rewarded') {
            banner.addEventListener('rewarded', () => {
                this.logEvent({
                    event: 'custom_event',
                    eventName: 'ad_request',
                    label: type,
                    action: 'rewarded',
                    pageName: this.route?.name as string || undefined,
                })

                this.triggerMessage({ status: 'rewarded' }, messageId)
            })
        }

        banner.addEventListener('closed', () => {
            this.triggerMessage({ status: 'close' }, messageId)
            this.isShowingFullscreenAd = false
            this.originalMessages.delete(messageId)
            this.logEvent({
                event: 'custom_event',
                eventName: 'ad_request',
                label: type,
                action: 'close',
                pageName: this.route?.name as string || undefined,
            })
        })

        banner.addEventListener('rendered', () => {
            this.triggerMessage({ status: 'open' }, messageId)
        })

        banner.addEventListener('viewable', () => {
            this.logEvent({
                event: 'custom_event',
                eventName: 'ad_request',
                label: type,
                action: 'show',
                pageName: this.route?.name as string || undefined,
            })

            this.triggerMessage({ status: 'show' }, messageId)
        })
    }

    private triggerMessage(payload: AdResponsePayload, messageId: string) {
        const message = this.originalMessages.get(messageId)
        if (!message) {
            return
        }
        const { action } = message.data
        this.eventBus.dispatch('adMessage', {
            action,
            type: 'adv',
            originalMessageEvent: message,
            payload,
        })
        if (payload.status === 'close') {
            this.eventBus.dispatch('adClose', {})
        }
    }

    updateTargeting(targeting: AdvServiceConfig['targeting']) {
        this.adProvider.updateTargeting(targeting)
        if (this.adFallback) {
            this.adFallback.updateTargeting(targeting)
        }
    }
}
