import { defineStore } from 'pinia'
import { ref } from 'vue'
import { request } from '@/utils/requests/request'

export const useInviteStore = defineStore('invitePage', () => {
    const inviterName = ref<string | undefined>(undefined)

    async function getInviterName(inviteCode: string): Promise<void> {
        const { data } = await request(`/api/v1/referral/username/${inviteCode}`)
        inviterName.value = data ? data.username : undefined
    }

    async function setInviter(inviteCode: string) {
        await request(`/api/v1/referral/invite/${inviteCode}`)
    }

    return {
        inviterName,
        getInviterName,
        setInviter,
    }
})
