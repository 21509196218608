<template>
    <Layout>
        <ErrorContent
            :type="type"
            :randomGame="randomGame"
            showButtons
        />
    </Layout>
</template>
<script setup lang="ts">
import {
    onMounted,
    onServerPrefetch,
} from 'vue'
import { storeToRefs } from 'pinia'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import { useCategoriesStore } from '@/store/categories-store'
import ErrorContent from '@/components_new/ErrorContent/ErrorContent.vue'
import { ErrorStatus } from '@/store/app-store'

const categoriesStore = useCategoriesStore()
const {
    randomGame,
} = storeToRefs(categoriesStore)

defineProps<{
    type: ErrorStatus
}>()

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

onMounted(async () => {
    await categoriesStore.setMainData()
})

</script>
