// category icons
import Action from './categories-icons/action.svg?inline'
import Adventure from './categories-icons/adventure.svg?inline'
import AllGames from './categories-icons/all_games.svg?inline'
import Applications from './categories-icons/applications.svg?inline'
import Baby from './categories-icons/baby.svg?inline'
import Balloons from './categories-icons/balloons.svg?inline'
import Bejeweled from './categories-icons/bejeweled.svg?inline'
import Boys from './categories-icons/boys.svg?inline'
import Cards from './categories-icons/cards.svg?inline'
import Casino from './categories-icons/casino.svg?inline'
import Clicker from './categories-icons/clicker.svg?inline'
import CompleteReg from './categories-icons/complete_reg.svg?inline'
import Cook from './categories-icons/cook.svg?inline'
import Economic from './categories-icons/economic.svg?inline'
import Educational from './categories-icons/educational.svg?inline'
import Farming from './categories-icons/farming.svg?inline'
import Girls from './categories-icons/girls.svg?inline'
import Horrors from './categories-icons/horrors.svg?inline'
import Hypercasual from './categories-icons/hypercasual.svg?inline'
import Imitations from './categories-icons/imitations.svg?inline'
import Io from './categories-icons/io.svg?inline'
import Kids from './categories-icons/kids.svg?inline'
import Match3 from './categories-icons/match3.svg?inline'
import Midcore from './categories-icons/midcore.svg?inline'
import New from './categories-icons/new.svg?inline'
import Popular from './categories-icons/popular.svg?inline'
import Purchase from './categories-icons/purchase.svg?inline'
import Puzzle from './categories-icons/puzzle.svg?inline'
import Quiz from './categories-icons/quiz.svg?inline'
import Racing from './categories-icons/racing.svg?inline'
import Random from './categories-icons/random.svg?inline'
import Recommended from './categories-icons/recommended.svg?inline'
import Role from './categories-icons/role.svg?inline'
import Shooting from './categories-icons/shooting.svg?inline'
import Simulator from './categories-icons/simulator.svg?inline'
import Soccer from './categories-icons/soccer.svg?inline'
import Social from './categories-icons/social.svg?inline'
import Special from './categories-icons/special.svg?inline'
import Sports from './categories-icons/sports.svg?inline'
import Stickman from './categories-icons/stickman.svg?inline'
import Strategy from './categories-icons/strategy.svg?inline'
import Tabletop from './categories-icons/tabletop.svg?inline'
import Tests from './categories-icons/tests.svg?inline'
import ThreeD from './categories-icons/three_d.svg?inline'
import TrendingNow from './categories-icons/trending_now.svg?inline'
import TwoPlayer from './categories-icons/two_player.svg?inline'
import Arcade from './categories-icons/arcade.svg?inline'
import Multiplayer from './categories-icons/multiplayer.svg?inline'
// common icons
import Check from './icons/check.svg?inline'
import ChevronDown from './icons/chevron-down.svg?inline'
import ChevronL from './icons/chevron-l.svg?inline'
import ChevronR from './icons/chevron-r.svg?inline'
import ChevronUp from './icons/chevron-up.svg?inline'
import Close from './icons/close.svg?inline'
import Collapse from './icons/collapse.svg?inline'
import FennekCircle from './icons/fennek-circle.svg?inline'
import Fennek from './icons/fennek.svg?inline'
import Fullscreen from './icons/fullscreen.svg?inline'
import Hand from './icons/hand.svg?inline'
import Help from './icons/help.svg?inline'
import Home from './icons/home.svg?inline'
import Key from './icons/key.svg?inline'
import Loader from './icons/loader.svg?inline'
import Logout from './icons/logout.svg?inline'
import Menu from './icons/menu.svg?inline'
import Play from './icons/play.svg?inline'
import Report from './icons/report.svg?inline'
import Sdk from './icons/sdk.svg?inline'
import Send from './icons/send.svg?inline'
import Share from './icons/share.svg?inline'
import Timer from './icons/timer.svg?inline'
import Trash from './icons/trash.svg?inline'
import UserAdd from './icons/user-add.svg?inline'
import Wallet from './icons/wallet.svg?inline'
import Shop from './icons/shop.svg?inline'
import FennekCoin from './icons/fennek-coin.png'

// Added new SVG — run `npx svgo -f src/components_new/Icon/icons`

export const icons = {
    // category icons
    action: Action,
    arcade: Arcade,
    adventure: Adventure,
    all_games: AllGames,
    applications: Applications,
    baby: Baby,
    balloons: Balloons,
    bejeweled: Bejeweled,
    boys: Boys,
    cards: Cards,
    casino: Casino,
    clicker: Clicker,
    complete_reg: CompleteReg,
    cook: Cook,
    economic: Economic,
    educational: Educational,
    farming: Farming,
    girls: Girls,
    horrors: Horrors,
    hypercasual: Hypercasual,
    imitations: Imitations,
    io: Io,
    kids: Kids,
    match3: Match3,
    midcore: Midcore,
    multiplayer: Multiplayer,
    new: New,
    popular: Popular,
    purchase: Purchase,
    puzzle: Puzzle,
    quiz: Quiz,
    racing: Racing,
    random: Random,
    recommended: Recommended,
    role: Role,
    shooting: Shooting,
    simulator: Simulator,
    soccer: Soccer,
    social: Social,
    special: Special,
    sports: Sports,
    stickman: Stickman,
    strategy: Strategy,
    tabletop: Tabletop,
    tests: Tests,
    three_d: ThreeD,
    trending_now: TrendingNow,
    two_player: TwoPlayer,

    // common icons
    check: Check,
    chevron_down: ChevronDown,
    chevron_l: ChevronL,
    chevron_r: ChevronR,
    chevron_up: ChevronUp,
    close: Close,
    collapse: Collapse,
    fennek_circle: FennekCircle,
    fennek: Fennek,
    fullscreen: Fullscreen,
    hand: Hand,
    help: Help,
    home: Home,
    key: Key,
    loader: Loader,
    logout: Logout,
    menu: Menu,
    play: Play,
    report: Report,
    sdk: Sdk,
    send: Send,
    share: Share,
    timer: Timer,
    trash: Trash,
    user_add: UserAdd,
    wallet: Wallet,
    fennek_coin: FennekCoin,
    shop: Shop,
}

export type IconNameType = keyof typeof icons
