export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© Playgama, ", _interpolate(_named("year"))])},
        "developers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developers"])},
        "advertisers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertisers"])},
        "publishers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishers"])},
        "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
        "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
        "categories_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games Categories"])}
      }
    }
  })
}
