<template>
    <div :class="$style.sidebar">
        <Logo :class="$style.logo" />
        <div :class="$style.sections">
            <div v-if="isUserInfoLoaded" :class="$style.section">
                <div v-if="isAuthorized && user" :class="$style.auth_view">
                    <RouterLink to="/profile" :class="[$style.auth_user, $style.links_item, {[$style.disabled]: isUserProfilePage}]" :disabled="isUserProfilePage">
                        <UserAvatar
                            :class="$style.avatar"
                            :avatar="user.avatar"
                            :firstName="user.firstName"
                            :lastName="user.lastName"
                        />
                        <Typography :class="$style.user_name" type="label" size="m" :accent="true" :responsive="false">
                            {{ `${user.firstName} ${user.lastName}` }}
                        </Typography>
                        <span :class="$style.spacer"/>
                        <Icon :class="$style.chevron" name="chevron_r" v-if="!isUserProfilePage"/>
                    </RouterLink>
                    <template v-if="!isUserProfilePage">
                        <span :class="$style.inner_sep"></span>
                        <RouterLink to="/profile" :class="$style.p2e_auth">
                            <Icon name="fennek_coin"/>
                            <Typography type="header" size="xs" :responsive="false" :class="$style.use_balance">
                                <Balance :balance="userBalance" />
                            </Typography>
                        </RouterLink>
                    </template>
                </div>
                <PromoSignInVertical />
            </div>
            <TasksWidget>
                <PromoInviteVertical title="20 000 +" :class="$style.promo_invite"/>
            </TasksWidget>
            <button :class="[$style.section, $style.links_item, $style.chevron_button]" @click="openCategories">
                <Typography type="label" size="s" :responsive="false">{{ t('btn_categories') }}</Typography>
                <Icon :class="$style.chevron" name="chevron_r" />
            </button>
            <RouterLink to="/shop" :class="[$style.section, $style.links_item, $style.chevron_button, {[$style.disabled]: isShop}]" :disabled="isShop">
                <Typography type="label" size="s" :responsive="false">{{ t('btn_shop') }}</Typography>
                <Icon :class="$style.chevron" name="chevron_r" v-if="!isShop"/>
            </RouterLink>
            <div :class="$style.section">
                <Typography is="ul" :class="$style.links" type="label" size="s" :accent="true" :responsive="false">
                    <li v-for="(item, index) in items" :key="item.title">
                        <span v-if="index !== 0" :class="$style.inner_sep"></span>
                        <a v-if="item.external" :href="item.link" :class="$style.links_item">
                            {{ item.title }}
                        </a>
                        <RouterLink v-else-if="item.link" :to="item.link" :class="$style.links_item">
                            {{ item.title }}
                        </RouterLink>
                    </li>
                </Typography>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { RouterLink, useRoute } from 'vue-router'
import { useUserStore } from '@/store/user-store/user-store'
import { useLayoutStore } from '@/store/layout-store'
import Logo from '@/components_new/Logo/Logo.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import UserAvatar from '@/components_new/UserAvatar.vue'
import Typography from '@/components_new/Typography.vue'
import TasksWidget from '@/components_new/TaskItems/TasksWidget.vue'
import Balance from '@/components_new/Balance.vue'
import PromoSignInVertical from '@/components_new/PromoItems/components/PromoSignInVertical.vue'
import PromoInviteVertical from '@/components_new/PromoItems/components/PromoInviteVertical.vue'

const { t } = useI18n()

const items = [
    {
        title: t('developers'),
        link: 'https://playgama.com/developers',
        external: true, // Do not remove. This route is not in the Vue app
    },
    {
        title: t('advertisers'),
        link: '/advertisers',
    },
    {
        title: t('publishers'),
        link: '/publishers',
    },
]

const { setCategoriesModalOpened } = useLayoutStore()

function openCategories() {
    setCategoriesModalOpened(true)
}

const userStore = useUserStore()
const route = useRoute()
const isUserProfilePage = computed(() => route.name === 'userProfile')
const isShop = computed(() => route.name === 'shop')

const {
    user, isAuthorized, userBalance, isUserInfoLoaded,
} = storeToRefs(userStore)

</script>

<style module>
.sidebar {
    display: flex;
    flex-direction: column;
}

.logo {
    margin: 0 auto;
    height: 88px;
    box-sizing: border-box;
    padding: 24px 0;
    --logo-width: 184px;
    --logo-height: auto;
}

.promo_invite {
    --promo-image-width: 160px;
    --promo-image-height: 160px;
}

.sections {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.section {
    border-radius: 16px;
    background: #17171a;
}

.links {
    padding: 4px 12px;
}

.links_item {
    display: block;
    padding: 12px 0;
    color: rgba(255, 255, 255, 0.6);
}
.links_item:hover {
    color: rgba(255, 255, 255, 1);
}

.links_item.disabled {
  cursor: default;
}

.links_item.disabled:hover {
  color: rgba(255, 255, 255, 0.6);
}

.chevron {
    height: 16px;
}

.inner_sep {
    height: 1px;
    display: block;
    background: rgba(255, 255, 255, 0.08);
}

.chevron_button {
    padding: 20px 10px 20px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.spacer {
    flex-grow: 1
}

.auth_view {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
}

.auth_user {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
}

.auth_user > * {
    flex-shrink: 0;
}

.user_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    color: #fff;
}

.p2e_auth {
    display: flex;
    align-items: center;
    gap: 8px;
}

.p2e_auth > * {
    flex-shrink: 0;
}

.use_balance {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
}

</style>
<i18n lang="json">{
    "en": {
        "btn_shop": "Shop",
        "btn_categories": "Games categories",
        "developers": "Developers",
        "advertisers": "Advertisers",
        "publishers": "Publishers"
    }
}</i18n>
