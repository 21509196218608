import type { AdvAction } from '@/modules/adv'
import type { MessageAction } from '@/modules/main-frame'
import { IconNameType } from '@/components_new/Icon'

export enum GameProviders {
    GOOGLE_AD = 'googleAd',
    GAME_DISTRIBUTION = 'gameDistribution',
    YANDEX_AD = 'yandexAd',
    DEBUG_PROVIDER = 'debug',
}

export type TMessageAction = MessageAction & AdvAction

export type GameEvent = Omit<MessageEvent, 'data'> & {
    source: MessageEventSource,
    data: {
        id: string,
        action: TMessageAction,
        payload?: Record<string, unknown>,
        responseToId?: string,
        type: 'adv' | 'liveness' | 'error',
    }
}

type AvailableGAEvents = 'ad_request' | 'game_init' | 'navigation' | 'session_end' | 'ping' | 'click' | 'invite-friend' | 'sign-in'
type AvailablePGEvents = AvailableGAEvents | 'game_opened' | 'session_start'

export type AdType = 'interstitial' | 'rewarded'

export type PlaygamaLoggerEventType = {
    userId?: string,
    clid?: string,
    eventName: AvailablePGEvents,
    pageName?: string,
    gameHru?: string,
}

export type CustomEventForGa = {
    event: 'custom_event' | 'user_time',
    eventName: AvailableGAEvents,
    label: 'sticky' | AdType | number | string,
    action: 'empty' | 'show' | 'start' | 'rewarded' | 'close' | 'init' | string,
    clid?: 'direct' | string,
}

export type EventForLoggersType = {
    clid?: CustomEventForGa['clid'],
    event: CustomEventForGa['event'],
    eventName: AvailablePGEvents,
    label: CustomEventForGa['label'],
    action: CustomEventForGa['action'],
    pageName?: string,
    gameHru?: string,
}

export type PaginationType = {
    page: number
    pageSize: number,
}

export type PlatformType = 'ios' | 'android' | 'desktop'

export enum Platforms {
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
}

export enum GameOrientation {
    LANDSCAPE = 'landscape',
    PORTRAIT = 'portrait',
}

export type GameType = {
    id: string;
    hru: string;
    title: string;
    icon: string;
    preview: string;
    description: string;
    instruction: string;
    is_android: boolean;
    is_ios: boolean;
    is_desktop: boolean;
    tags: string[];
    categories: string[];
    game_url: string;
    screenshots: string[];
    videos?: {
        player_url: string;
        preview_url: string;
        thumbnail_url: string;
    }[];
    seoText: string;
    vertical: boolean;
    horizontal: boolean;
}

export type CategoriesType =
    'popular'
    | 'all_games'
    | 'recommended'
    | 'trending_now'
    | 'new'
    | 'random'
    | '2_player'
    | '3d'
    | 'action'
    | 'adventure'
    | 'arcade'
    | 'baby'
    | 'bejeweled'
    | 'boys'
    | 'clicker'
    | 'cooking'
    | 'farming'
    | 'girls'
    | 'hypercasual'
    | 'io'
    | 'multiplayer'
    | 'puzzle'
    | 'racing'
    | 'shooting'
    | 'sports'
    | 'stickman'
    | 'soccer'
    | 'social'
    | 'top_picks'
    | 'in_game_purchase'
    | 'cards'
    | 'kids'
    | 'educational'
    | 'quiz'
    | 'simulator'
    | 'strategy'
    | 'tabletop'
    | 'match3'
    | 'role'
    | 'casino'
    | 'balloons'
    | 'economic'
    | 'midcore'
    | 'horrors'
    | 'imitations'
    | 'applications'
    | 'tests'

export type SimplifiedGame =
    Omit<GameType, 'id' | 'icon' | 'description' | 'instruction' | 'platforms' | 'game_url' | 'orientation' | 'screenshots' | 'videos' | 'tags' | 'categories' | 'is_ios' | 'is_desktop' | 'is_android' | 'seoText' | 'vertical' | 'horizontal'>
    & {
    video?: {
        preview_url: string
    }
}

export type GameRecommendationType = 'recommended' | 'top_playgama' | 'related' | 'random'
export type GameRecommendation<T extends GameRecommendationType[]> = {
    [key in T[number]]: SimplifiedGame[]
}

export type CategoryDataType = {
    categories: {
        id: CategoriesType;
        games: SimplifiedGame[];
        totalCount: number;
    }[];
    pagination: {
        page: 0;
        pageSize: 0;
    };
}

export type APICategoryDataType = {
    categories: {
        id: CategoriesType;
        internal: boolean;
        games: GameType[];
        totalCount: number;
    }[];
    pagination: {
        page: 0;
        pageSize: 0;
    };
}

export type CategoryListType = {
    name: CategoriesType,
    href: string,
    icon: IconNameType,
    games?: SimplifiedGame[],
}

export type FormSubmitData = {
    message: string,
    email: string,
    page?: string,
}

export type UserSocialKeys = 'telegramAcc' | 'tonWallet'
export const SOCIALS = ['telegram', 'ton'] as const
export type SocialsName = typeof SOCIALS[number]
export type SocialType = 'account' | 'wallet'

export type UserSocialsType = {
    name: SocialsName,
    account?: string,
    type: SocialType,
    tag: string,
    key: UserSocialKeys,
    isSupport: boolean,
    validate: (value: string) => boolean,
}

export type User = {
    _id: string,
    firstName: string,
    lastName: string,
    avatar: string,
    fennecs: number,
    referralCode: string,
    telegramAcc?: string,
    tonWallet?: string,
    gamesPlayed?: number,
    createdAt?: number,
    currentStreak?: number,
    usersInvited?: number,
}

export type TaskType = 'VISIT_EVERYDAY' |'REFERRAL_INVITE' | 'RANDOM_GAME' | 'PLAY_GAME' | 'REGISTER' | 'REFERRAL_REGISTER'
export type TaskStatus = 'ACTIVE' | 'COMPLETED' | 'CLAIMED'

export type BaseTaskDto = {
    task_id: string
    type: TaskType
    status: TaskStatus
    coins: number
}

export type DailyTaskDto = BaseTaskDto
export type RandomTaskDto = BaseTaskDto & {
    settings: {
        game: SimplifiedGame
        seconds_to_play: number
    }
}

export type GameTaskDto = BaseTaskDto & {
    settings: {
        game: SimplifiedGame
        seconds_to_play: number
    }
}

export type ReferralTaskDto = BaseTaskDto & {
    settings: {
        invitations_not_claimed: number
    }
}

export type TaskDto = DailyTaskDto | RandomTaskDto | GameTaskDto | ReferralTaskDto

export type UserTasksStats = {
    wallet: {
        coins: number
    },
    tasks: TaskDto[]
}
