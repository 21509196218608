export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "page_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game room"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
        "balance_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
        "invite_friend_mobile_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 000 for a friend"])}
      }
    }
  })
}
