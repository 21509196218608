export const promoItemsTranslation: Record<string, Record<string, string>> = {
    en: {
        watch_ad_btn: 'Watch short Ad',
        watch_ad_alt: 'Watch short Ad',
        watch_ad_desc: 'Watch a short ad and get 5,000 coins',
        invite_btn: 'Invite friend',
        invite_alt: 'Invite friend',
        invite_desc: 'Get 20 000 coins for each friend you invite to play and earn',
        sign_in_btn: 'Login & Get',
        sign_in_alt: 'Login and Get coins',
        sign_in_desc: 'Play, earn, and spend coins. Sign up for free and start earning for real drops of TON',
        sign_in_title: '5000 coins',
    },
}
