<template>
    <component :is="component" :class="{
        [$style.gameCardsList]: true,
        [$style[appearance]]: true,
    }">
        <component
            v-if="title"
            :is="link ? 'RouterLink' : 'span'"
            :to="link"
            :class="$style.cardsListHeader"
        >
            <Icon
                v-if="icon"
                :name="icon"
                :class="$style.titleIcon"
            />
            <h2
                v-if="title"
                class="headerOne"
            >
                {{title}}
            </h2>
            <span v-if="title && isMore" :class="$style.more" class="textRegular">
                {{t('moreTag')}}
            </span>
        </component>
        <ul :class="$style.games">
            <li :class="$style.cardItem" v-for="(game, index) in games" :key="game.hru">
                <GameCard
                    :game="game"
                    :class="index === 0 ? $style[`${cardClass}First`] : $style[cardClass]"
                    :appearance="getAppearanceForIndex(index)"
                />
            </li>
        </ul>
    </component>
</template>

<script setup lang="ts">
import {
    computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import type { SimplifiedGame } from '@/types'
import Icon, { type IconProps } from '@/components/Icon/index.vue'
import GameCard from '@/components/GameCard.vue'
import { capitalizeFirstLetter } from '@/utils/helpers'

interface GameCardsListProps {
    appearance: 'aside' | 'big' | 'small' | 'medium' | 'highlighted' | 'tiny-rows',
    title?: string,
    icon?: IconProps['name'],
    isMore?: boolean,
    component?: string,
    link?: string,
    games: SimplifiedGame[],
}
const props = withDefaults(defineProps<GameCardsListProps>(), {
    component: 'section',
    isMore: false,
})

function getAppearanceForIndex(index: number) {
    if (props.appearance === 'highlighted') {
        return 'medium'
    }

    if (props.appearance === 'big' && index === 0) {
        return 'big'
    }

    if (props.appearance === 'aside' && index === 0) {
        return 'medium'
    }

    if (props.appearance === 'aside' && index !== 0) {
        return 'small'
    }

    if (props.appearance === 'small'
        || props.appearance === 'tiny-rows') {
        return 'small'
    }

    return 'medium'
}

const cardClass = computed(() => `card${capitalizeFirstLetter(props.appearance)}`)
const { t } = useI18n()
</script>

<style module>
/* general styles */
.gameCardsList .games {
    display: flex;
    margin-top: var(--gap-small);
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: var(--gap-regular);
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.gameCardsList .games::-webkit-scrollbar {
    display: none;
}

.titleIcon {
    margin-right: var(--gap-small);
}

.cardsListHeader {
    display: flex;
    align-items: center;
}

.more {
    align-self: end;
    margin-left: var(--gap-small);
    color: var(--white-25);
}

/* cards in the right panel */
.gameCardsList.aside .games {
    height: 128px;
    overflow-x: hidden;
}
.gameCardsList.aside .games .cardItem {
    width: 100px;
    height: 56px;
}

.cardaside {
    composes: captionLight from global;
    font-family: var(--font-primary);
}

.cardasideFirst {
    composes: headerTwo from global;
}

/* big */
.gameCardsList.big .games {
    height: 272px;
}
.gameCardsList.big .games .cardItem {
    width: 216px;
    height: 128px;
}
.gameCardsList.big .games .cardItem:first-child {
    width: 448px;
    height: 100%;
}

.cardBig {
    composes: headerTwo from global;
}

.cardBigFirst {
    composes: headerOne from global;
}

/* medium */
.gameCardsList.medium .games {
    height: 128px;
}
.gameCardsList.medium .games .cardItem,
.gameCardsList.medium .games .cardItem:first-child {
    width: 216px;
    height: 128px;
}

.cardMedium,
.cardMediumFirst {
    composes: headerTwo from global;
}

/* highlighted medium */
.gameCardsList.highlighted {
    padding: var(--gap-small) var(--gap-regular) var(--gap-regular);
    background-color: var(--dark-purple);
    border-radius: var(--radius-small);
}

.gameCardsList.highlighted .games {
    height: 128px;
    margin-right: var(--gap-negative-regular);
}
.gameCardsList.highlighted .games .cardItem,
.gameCardsList.highlighted .games .cardItem:first-child {
    width: 216px;
    height: 128px;
}

.cardHighlighted,
.cardHighlightedFirst {
    composes: headerTwo from global;
}

/* small */
.gameCardsList.small .games {
    height: 72px;
}

.gameCardsList.tiny-rows .games {
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
}

.gameCardsList.small .games .cardItem,
.gameCardsList.small .games .cardItem:first-child,
.gameCardsList.tiny-rows .games .cardItem,
.gameCardsList.tiny-rows .games .cardItem:first-child {
    width: 128px;
    height: 72px;
}

.cardSmall,
.cardSmallFirst,
.cardSmallFirst-2-rows {
    composes: accent from global;
    line-height: var(--line-height-light);
}

@media (--mobile) {
    .gameCardsList.big .games {
        height: 128px;
    }
    .gameCardsList.big .games .cardItem,
    .gameCardsList.big .games .cardItem:first-child {
        width: 216px;
        height: 100%;
    }

    .cardBig,
    .cardBigFirst {
        composes: headerTwo from global;
    }

    .gameCardsList.medium .games,
    .gameCardsList.small .games {
        height: 72px;
    }
    .gameCardsList.medium .games .cardItem,
    .gameCardsList.medium .games .cardItem:first-child,
    .gameCardsList.small .games .cardItem,
    .gameCardsList.small .games .cardItem:first-child{
        width: 128px;
        height: 100%;
    }

    .cardMedium,
    .cardMediumFirst,
    .cardSmall,
    .cardSmallFirst {
        composes: accent from global;
    }

    .gameCardsList.tiny-rows .games .cardItem,
    .gameCardsList.tiny-rows .games .cardItem:first-child {
        width: 98px;
        height: 55px;
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "moreTag": "More"
    }
}
</i18n>
