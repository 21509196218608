<!-- eslint-disable vuejs-accessibility/media-has-caption -->
<template>
    <CardContainer
        :is="props.game ? RouterLink : 'div'"
        :appearance="props.appearance"
        :class="$style.gameCard"
        :alt="props.game?.title"
        :src="props.game?.preview"
        :responsive="props.responsive"
        :to="gameLink"
        itemscope
        itemtype="https://schema.org/VideoGame"
        itemprop="url"
        @mouseenter="showVideo"
        @focus="showVideo"
    >
        <video
            v-if="isVideoAllowed && props.game?.video"
            :class="$style.video"
            :src="props.game.video.preview_url"
            loop
            muted
            playsInline
            autoplay
            itemprop="video"
        />
        <Typography size="s" accent :responsive="false" v-if="props.game" itemprop="name" :class="$style.title">{{ props.game.title }}</Typography>
    </CardContainer>
</template>

<script setup lang="ts">
import {
    computed,
    ref,
} from 'vue'
import { RouterLink } from 'vue-router'
import type { SimplifiedGame } from '@/types'
import CardContainer from '@/components_new/CardContainer.vue'
import Typography from '@/components_new/Typography.vue'

interface GameCardProps {
    game?: SimplifiedGame,
    appearance: 'big'|'medium',
    responsive?: boolean,
}

const props = defineProps<GameCardProps>()

const isVideoAllowed = ref(false)
const gameLink = computed(() => `/game/${props.game?.hru}`)

function showVideo() {
    isVideoAllowed.value = true
}
</script>

<style module>

.title, .video {
    display: none;
}

.gameCard:hover .video {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
}

.gameCard:hover .title {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 12px 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%);
    z-index: 2;
}
</style>
