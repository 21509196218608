<template>
    <RouterView v-if="pageStatus === 200 || manuallyHandledError"/>
    <ErrorPage :type="404" v-else-if="pageStatus === 404" />
    <ErrorPage :type="500" v-else-if="pageStatus >= 500" />
    <ModalSignIn/>
</template>
<script lang="ts" setup>
/* eslint-disable */
// необходимый для SSR и правильных статусов ответов враппер
import {
    computed, onBeforeMount, onMounted, onServerPrefetch, useSSRContext,
} from 'vue'
import { storeToRefs } from 'pinia'
import { RouteLocationNormalized, useRoute, useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useAppStore } from '@/store/app-store'
import { useAdsStore } from '@/store/ads-store'
import { useLoggerStore } from '@/store/logger-store'
import { useCategoriesStore } from '@/store/categories-store'
import { getCurrentPlatform } from '@/utils/helpers'
import { GameProviders, PlatformType } from '@/types'
import { useUserStore } from '@/store/user-store/user-store'
import { sendEvent } from '@/utils/analytics'
import ErrorPage from '@/pages/Error/ErrorPage.vue'
import { getUACFromCookies } from '@/utils/cookie-parser'
import ModalSignIn from '@/components_new/ModalItems/components/ModalSignIn.vue'

const appStore = useAppStore()
const adsStore = useAdsStore()
const loggerStore = useLoggerStore()
const $router = useRouter()
const $route = useRoute()

const categoriesStore = useCategoriesStore()

const {
    pageStatus,
} = storeToRefs(appStore)

const userStore = useUserStore()

const CLID_PARAM_NAME = 'clid'
const GD_GAME_ID_KEY = 'gd_game_id'
const GD_KEY = 'use_gd_ads'
const taskUpdateTimeOutMs = Number(process.env.VUE_APP_USER_TASKS_POLLING_MS) || 60000

function getPageScripts(route: typeof $route) {
    const scripts = []
    if (!route.query[GD_KEY]) {
        scripts.push(
            // gtm
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${process.env.VUE_APP_GA_MEASUREMENT_ID}');`,
        )
    }

    return scripts
}

const manuallyHandledError = computed(() => $route.meta.manuallyHandledErrors)

// предзаданные параметры страницы
useHead(computed(() => ({
    title: 'Free Online Games Now on Playgama 🎮 Play NOW!',
    meta: [
        {
            name: 'description',
            content: 'Discover the best free online games at Playgama! Enjoy a wide variety of high-quality browser games without downloads, registration, or pop-ups. ✨ Play now on desktop, tablet, and mobile. 🕹️',
        },
        {
            name: 'validation-token',
            content: 'hb42hn0e5uyz0bfp',
        },
    ],
    script: getPageScripts($route),
})))

function getPageInfo(page: RouteLocationNormalized) {
    return `${page.name?.toString()} ${page.params.game || page.params.category || ''}`
}

const ctx = useSSRContext()
onServerPrefetch(() => {
    if (ctx?.pageStatus) {
        appStore.setPageStatus(ctx.pageStatus)
    } else {
        appStore.$reset()
        appStore.setPlatform(ctx?.platform as PlatformType)
    }
})

onBeforeMount(async () => {
    // кажется тут пора навести порядок. пока хороших идей нет,
    // но пусть этот комментарий на них натолкнет когда-нибудь
    let currentPageSessionStartedAt = Date.now()
    const currentSessionStartedAt = Date.now()
    let currentPageData = getPageInfo($route)

    $router.beforeEach((to) => {
        appStore.setPageStatus(200)

        const delta = Date.now() - currentPageSessionStartedAt
        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'navigation',
            label: delta / 1000,
            action: currentPageData,
            pageName: $route.name as string || undefined,
        })

        currentPageSessionStartedAt = Date.now()
        currentPageData = getPageInfo(to)

        if (to.name === 'game-page' || to.name === 'export-game-page') {
            adsStore.updateTargeting({ hru: to.params.game })
        } else {
            adsStore.updateTargeting({ hru: '' })
        }
    })

    window.addEventListener('beforeunload', () => {
        const delta = Date.now() - currentPageSessionStartedAt
        const sessionTime = Date.now() - currentSessionStartedAt
        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'navigation',
            label: delta / 1000,
            action: currentPageData,
            pageName: $route.name as string || undefined,
        })

        loggerStore.logEvent({
            event: 'user_time',
            eventName: 'session_end',
            label: sessionTime / 1000,
            action: currentPageData,
            pageName: $route.name as string || undefined,
        })
    })

    const ua = window.navigator.userAgent
    const platform = getCurrentPlatform(ua)
    appStore.setPlatform(platform)

    const isGD = !!$route.query[GD_KEY]
    const clid = $route.query[CLID_PARAM_NAME]
    if (clid) {
        appStore.setClid(clid as string)
    }
    const GD_GAME_ID = $route.query[GD_GAME_ID_KEY]
    if (GD_GAME_ID) {
        window.gd_id = GD_GAME_ID as string
    }

    const userAcquisitionParams = getUACFromCookies(document.cookie)

    const gamePageTargeting: Record<string, string> = {}

    if ($route.name === 'game-page' || $route.name === 'export-game-page') {
        gamePageTargeting.hru = $route.params.game
    }

    const configKey = isGD ? GameProviders.GAME_DISTRIBUTION : GameProviders.GOOGLE_AD
    adsStore.setAdServiceConfig({
        configKey,
        targeting: {
            playgama_clid: clid as string || 'direct',
            ...userAcquisitionParams,
            ...gamePageTargeting,
        },
        logEvent: loggerStore.logEvent,
        route: $route,
    })
})

function checkTasks() {
    if (
        !document.hidden
    ) {
        // todo: websockets
        setInterval(() => {
            userStore.getUserTasks()
        }, taskUpdateTimeOutMs)
    }
}

onMounted(async () => {
    if ($route.name !== 'export-game-page') {
        categoriesStore.setRecommendedGames() // ignore the promise intentionally
        const user = await userStore.getUser()
        sendEvent({
            eventName: 'session_start',
            userId: user ? user._id : undefined,
            clid: appStore.clid,
            pageName: $route.name as string || undefined,
        })

        // watch тут не нужен, так как авторизация на текущий момент перезапрашивает всю страницу
        if (user) {
            // ignore the promise intentionally
            userStore.getUserTasks()
            checkTasks()
        }

        appStore.initAuthBannerOpen()
    }

    // this enables css :active pseudo-class on touch devices
    const noop = () => {}
    document.body.addEventListener('touchstart', noop, false)
})

</script>
