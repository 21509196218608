// eslint-disable-next-line
import { FloatingVueConfig } from 'floating-vue/dist/config'
import 'floating-vue/dist/style.css'

const FloatingThemes: FloatingVueConfig = {
    themes: {
        'common-tooltip': {
            placement: 'bottom',
            delay: { show: 200, hide: 0 },
            $extend: 'tooltip',
            skidding: 0,
            distance: 15,
        },
    },
}

export default FloatingThemes
