export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play now"])},
        "playNoAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just Play"])},
        "playAndEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play & Earn"])},
        "continue_play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue play"])},
        "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
        "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
        "link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied!"])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
        "fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullscreen"])},
        "exit_fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit game"])},
        "limitedAvailablity1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Available only on ", _interpolate(_named("platform"))])},
        "limitedAvailablityMany": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Available only on ", _interpolate(_named("platfroms")), " and ", _interpolate(_named("lastPlatform"))])},
        "desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desktop"])},
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
        "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iOS"])}
      }
    }
  })
}
