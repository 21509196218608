export const tasksTranslation: Record<string, Record<string, string>> = {
    en: {
        daily_bonus_alt: 'Daily bonus',
        random_game_alt: 'Random game',
        collect_invite_alt: 'Collect bonus for invited friends',
        daily_bonus_description: 'Collect daily bonus',
        random_game_description: 'Play any game for {min} min',
        game_label: 'Play {min} min',
        play: 'Play',
        claim: 'Claim',
        claimed: 'Claimed',
        collect_invite: 'Collect {coins} for 1 invited friend',
        collect_invite_many: 'Collect {coins} for {friends} invited friends',
        collect_invite_widget: 'Collect {coins} for 1 invitation',
        collect_invite_widget_many: 'Collect {coins} for {friends} for invitations',
        error_title: 'There was an issue loading the tasks',
        error_button: 'Reload',
    },
}
