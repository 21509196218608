<!-- eslint-disable vuejs-accessibility/media-has-caption -->
<template>
    <RouterLink
        :to="gameLink"
        :class="styles.gameCard"
        itemscope
        itemtype="https://schema.org/VideoGame"
        itemprop="url"
        @mouseenter="showVideo"
        @focus="showVideo"
    >
        <img
            :alt="game.title"
            :src="game.preview"
            :class="styles.img"
            :srcset="srcset"
            itemprop="image"
            loading="lazy"
            width="1"
            height="1"
        />
        <video
            v-if="isVideoAllowed && game.video"
            :class="styles.video"
            :src="game.video.preview_url"
            loop
            muted
            playsInline
            autoplay
            itemprop="video"
        />
        <span itemprop="name" :class="styles.title">{{ props.game.title }}</span>
    </RouterLink>
</template>

<script setup lang="ts">
import {
    useCssModule,
    computed,
    ref, watch,
} from 'vue'
import type { SimplifiedGame } from '@/types'

interface GameCardProps {
    game: SimplifiedGame,
    appearance: 'big'|'medium'|'small'
}

const styles = useCssModule()
const props = defineProps<GameCardProps>()

const isVideoAllowed = ref(false)
const gameLink = computed(() => `/game/${props.game.hru}`)
function showVideo() {
    isVideoAllowed.value = true
}

function transformSrcSet(input: string) {
    if (!input) return ''
    switch (props.appearance) {
    case 'big':
        return input
    case 'medium':
        return `${input.replace('enlarged', 'medium')},
            ${input} 2x`
    case 'small':
    default:
        return `${input.replace('enlarged', 'small')},
            ${input.replace('enlarged', 'medium')} 2x`
    }
}

const srcset = ref(transformSrcSet(props.game.preview))

watch(() => props.game.preview, () => {
    srcset.value = transformSrcSet(props.game.preview)
})
</script>

<style module>
.gameCard {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: var(--radius-small);
    overflow: hidden;
    position: relative;
    background-color: var(--white-08);
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title, .video {
    display: none;
}

.gameCard:hover .video {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
}

.gameCard:hover .title {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: var(--gap-xsmall);
    box-sizing: border-box;
    display: flex;
    align-items: end;
    background: linear-gradient(180deg, var(--white-25), rgba(0, 0, 0, 0.8));
    z-index: 2;
}
</style>
