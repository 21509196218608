<template>
    <div :class="[$style.overlay, props.isOpen ? $style.open : '']"><slot /></div>
</template>

<script lang="ts" setup>
import {
    watch,
    onBeforeUnmount,
} from 'vue'

interface OverlayProps {
    isOpen?: boolean
}

const props = withDefaults(defineProps<OverlayProps>(), {
    isOpen: false,
})

watch(() => props.isOpen, (isOpen) => {
    if (isOpen) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = ''
    }
})

onBeforeUnmount(() => {
    document.body.style.overflow = ''
})
</script>

<style module>
.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: var(--gap-xlarge) var(--gap-xlarge)  var(--gap-xlarge) var(--gap-regular) ;
    box-sizing: border-box;
    background-color: var(--black-50);
    z-index: 10;
}

.overlay.open {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (--mobile) {
    .overlay {
        padding: var(--gap-regular);
    }
    .overlay.open {
        overflow-y: scroll;
    }
}
</style>
