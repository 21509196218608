import { defineStore } from 'pinia'
import { ref } from 'vue'
import type {
    User,
    UserSocialsType,
} from '@/types'
import { TaskDto } from '@/types'
import { request } from '@/utils/requests/request'
import { claimTask, getTasks } from '@/utils/requests/tasks'
import { sortTaskByPriority } from '@/store/user-store/utils'

const DEFAULT_INTERVAL_TO_SHOW_ERROR = 5000

const DEFAULT_COINS = 1000

export const useUserStore = defineStore('user', () => {
    const user = ref<User | null>(null)
    const userLoadingPromise = ref<Promise<User | null> | null>(null)
    const isUserInfoLoaded = ref(false)
    const isAuthorized = ref<boolean>(false)
    const userError = ref<number | null>(null)
    const tasks = ref<TaskDto[]>([])
    const tasksError = ref<boolean>(false)
    const userBalance = ref<number>(0)

    function getUser(): Promise<User | null> {
        if (userLoadingPromise.value) {
            return userLoadingPromise.value
        }

        // eslint-disable-next-line no-async-promise-executor
        userLoadingPromise.value = new Promise(async (resolve) => {
            const { data, originalResponse } = await request<User>('/api/v1/user/current')
            isUserInfoLoaded.value = true
            if (!data) {
                isAuthorized.value = false
                user.value = null
                userError.value = originalResponse.status
                setTimeout(() => {
                    userError.value = null
                }, DEFAULT_INTERVAL_TO_SHOW_ERROR)
                resolve(null)
                return
            }

            isAuthorized.value = true
            user.value = data
            resolve(data)
        })

        return userLoadingPromise.value
    }

    async function getUserTasks() {
        try {
            const data = await getTasks()
            tasksError.value = false
            tasks.value = sortTaskByPriority(data.tasks)
            userBalance.value = data.wallet.coins
        } catch {
            tasksError.value = true
            tasks.value = []
            userBalance.value = DEFAULT_COINS
        }
    }

    async function claimUserTasks(taskId: string): Promise<void> {
        const oldUserBalance = userBalance.value
        const oldTasksData = tasks.value
        /* eslint-disable-next-line camelcase  */
        userBalance.value += tasks.value.find(({ task_id }) => task_id === taskId)!.coins
        tasks.value = tasks.value.map((task) => {
            if (task.task_id === taskId) {
                return { ...task, status: 'CLAIMED' }
            }
            return task
        })

        try {
            const data = await claimTask(taskId)
            tasks.value = sortTaskByPriority(data.tasks)
            userBalance.value = data.wallet.coins
        } catch (error) {
            tasks.value = oldTasksData
            userBalance.value = oldUserBalance
            tasksError.value = true
        }
    }

    async function logout() {
        userLoadingPromise.value = Promise.resolve(null)
        await request('/api/v1/auth/logout', { method: 'post' })
        user.value = null
        isAuthorized.value = false
        tasks.value = []
    }

    async function updateSocials({ key, account }: UserSocialsType) {
        const { originalResponse } = await request('/api/v1/user', {
            method: 'post',
            body: JSON.stringify({
                [key]: account,
            }),
        })
        if (originalResponse.ok) {
            user.value![key] = account
        } else {
            // ???
        }
    }

    return {
        user,
        isAuthorized,
        isUserInfoLoaded,
        getUser,
        getUserTasks,
        tasks,
        logout,
        updateSocials,
        tasksError,
        userBalance,
        claimUserTasks,
    }
})
