<template>
    <div :class="$style.promo">
        <div :class="$style.texts">
            <Typography is="h2" size="xs" :responsive="false">{{ t('title') }}</Typography>
            <div :class="$style.text">
                <Typography type="paragraph" size="xs" >{{ t('text') }}</Typography>
            </div>
        </div>
        <div :class="$style.categories">
            <Button v-for="category in categories" :key="category.name" :link="`/category/${category.name}`" color="graphite" :iconLeft="category2icon[category.name]">
                {{ t(category.name) }}
            </Button>
        </div>
        <img :src="fennekImg" :alt="t('img_alt')" :class="$style.fennek" />
    </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { categoriesTranslation } from '@/utils/translations/categories'
import Button from '@/components_new/Button.vue'
import { category2icon } from '@/components_new/Icon/categories'
import Typography from '@/components_new/Typography.vue'
import fennekImg from './images/fennek.png'

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const categories = [
    { name: 'arcade' },
    { name: 'sports' },
    { name: 'hypercasual' },
    { name: 'girls' },
    { name: 'cards' },
    { name: 'io' },
    { name: 'adventure' },
] as const

</script>
<style module>
.promo {
    border-radius: 16px;
    background: #17171A;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
    padding: 24px 24px 0;
}

.texts {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.text {
    color: rgba(255, 255, 255, 0.8);
}

.categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}

.fennek {
    display: block;
    width: 154px;
    height: 94px;
}
</style>
<i18n lang="json">{
    "en": {
        "title": "Free Online Games at Playgama",
        "text": "Playgama features the latest and best free online games. You can enjoy playing fun games without interruptions from downloads, intrusive ads, or pop-ups. Just load up your favorite games instantly in your web browser and enjoy the experience.",
        "img_alt": "Fennek"
    }
}</i18n>
