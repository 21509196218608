<template>
    <ul :class="$style.footer">
        <li
            v-for="(item, index) in items"
            :key="item.title"
            :class="!isBigItem ? 'captionLight' : index === items.length - 1 ? 'subheader' : 'title'"
        >
            <a v-if="item.external" :href="item.link">
                {{ t(item.title) }}
            </a>
            <RouterLink v-else-if="item.link" :to="item.link">
                {{ t(item.title) }}
            </RouterLink>
            <template v-else>{{ t(item.title) }}</template>
        </li>
    </ul>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

interface MenuListProps {
    isBigItem?: boolean
}

withDefaults(defineProps<MenuListProps>(), {
    isBigItem: false,
})

const { t } = useI18n()

const items = [
    {
        title: 'developers',
        link: 'https://playgama.com/developers',
        external: true, // Do not remove. This route is not in the Vue app
    },
    {
        title: 'advertisers',
        link: '/advertisers',
    },
    {
        title: 'publishers',
        link: '/publishers',
    },
    {
        title: 'privacy-policy',
        link: '/confidential',
    },
    {
        title: 'contacts',
        link: '/contacts',
    },
    {
        title: 'copyright',
        link: null,
    },
]
</script>

<style module>
.footer {
    padding: var(--gap-large) 0 0;
    margin-top: auto;
}

.footer li {
    margin-bottom: var(--gap-small);
    color: var(--white-50);
}

.footer li:hover {
    color: var(--white);
}

.footer li:last-child {
    margin-top: var(--gap-large);
    margin-bottom: 0;
    color: var(--white-25);
}

@media (--desktop-small) {
    .footer {
        padding: var(--gap-large) 0 0;
    }
}

@media (--mobile) {
    .footer {
        padding: var(--gap-xlarge);
    }
    .footer li {
        color: var(--white);
    }

    .footer li:last-child {
        display: none;
    }
}
</style>

<i18n lang="json">
{
    "en": {
        "privacy-policy": "Privacy Policy",
        "advertisers": "Advertisers",
        "publishers": "Publishers",
        "developers": "Developers",
        "contacts": "Contact us",
        "copyright": "© 2024 Playgama"
    }
}
</i18n>
