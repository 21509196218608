<template>
    <CardCarousel
        :items="items"
        :dummy="isDummy"
        :title="props.title"
        :link="props.link"
        :lastItemLink="props.lastItemLink"
        :rows="props.big ? 1 : 2"
    >
        <template v-slot="{ index, item: game, className, bigCardClassName }">
            <GameCard
                :game="game as (SimplifiedGame | undefined)"
                :appearance="itemIsBig(index) ? 'big' : 'medium'"
                responsive
                :class="{[className]: true, [bigCardClassName]: itemIsBig(index)}"
            />
        </template>
    </CardCarousel>
</template>
<script lang="ts" setup>
import {
    computed,
} from 'vue'
import { SimplifiedGame } from '@/types'
import GameCard from '@/components_new/GameCard.vue'
import CardCarousel from '@/components_new/CardCarousel.vue'

interface GameCarouselProps {
    title?: string,
    link?: string,
    games?: SimplifiedGame[]
    big?: boolean
    firstItemBig?: boolean
    lastItemLink?: string
}

const props = defineProps<GameCarouselProps>()

const items = computed(() => props.games || Array<undefined>(20).fill(undefined))
const isDummy = computed(() => !props.games)

function itemIsBig(index: number): boolean {
    if (props.big || (props.firstItemBig && index === 0)) {
        return true
    }
    return false
}
</script>
