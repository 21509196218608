<template>
    <div :class="$style.footer">
        <div :class="$style.links_group">
            <Typography is="ul" type="paragraph" size="xs" :class="$style.links">
                <li>
                    <RouterLink to="/" :class="$style.links_item">
                        {{ copyright }}
                    </RouterLink>
                </li>
            </Typography>
        </div>
        <div :class="$style.links_group">
            <Typography is="ul" type="paragraph" size="xs" :class="$style.links">
                <li v-for="(item, index) in items" :key="item.title">
                    <span v-if="index !== 0" :class="$style.links_sep"></span>
                    <a v-if="item.external" :href="item.link" :class="$style.links_item">
                        {{ item.title }}
                    </a>
                    <RouterLink v-else-if="item.link" :to="item.link" :class="$style.links_item">
                        {{ item.title }}
                    </RouterLink>
                </li>
            </Typography>
        </div>
        <div :class="$style.links_group">
            <Typography is="h3" type="paragraph" size="m" :class="$style.links_title">{{ t('categories_title') }}</Typography>
            <Typography type="paragraph" size="xs"  is="ul" :class="[$style.links, $style.categories]">
                <li v-for="(item) in menuCategoriesList" :key="item.title">
                    <RouterLink :to="item.href" :class="$style.links_item">
                        {{ t(item.name) }}
                    </RouterLink>
                </li>
            </Typography>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
import { useCategoriesStore } from '@/store/categories-store'
import { categoriesTranslation } from '@/utils/translations/categories'
import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const categoriesStore = useCategoriesStore()

const {
    // contentCategoriesList,
    menuCategoriesList,
    // menuHighlightCategoriesList,
    // recommendedGames,
} = storeToRefs(categoriesStore)

const items = [
    {
        title: t('developers'),
        link: 'https://playgama.com/developers',
        external: true, // Do not remove. This route is not in the Vue app
    },
    {
        title: t('advertisers'),
        link: '/advertisers',
    },
    {
        title: t('publishers'),
        link: '/publishers',
    },
    {
        title: t('privacy_policy'),
        link: '/confidential',
    },
    {
        title: t('contact_us'),
        link: '/contacts',
    },
]

const copyright = t('copyright', { year: new Date().getFullYear() })

</script>

<style module>
.footer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 24px;
    padding-top: 16px;
    padding-bottom: 40px;
}

.links_group {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 16px;
    padding-top: 16px;
}

.links {
    columns: 2 auto;
}

.links_title {
    display: block;
    margin-bottom: 12px;
    color: rgba(255,255,255,0.6);
}

.links_item {
    display: block;
    padding: 4px 0;
    color: rgba(255,255,255,0.6);
}
.links_item:hover {
    color: rgba(255,255,255,1);
}

@media (--tablet-plus) {
    .footer {
        display: block;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        columns: 6 auto;
        padding-top: 24px;
    }

    .links_group {
        border-top: none;
        margin-top: unset;
        padding-top: unset;
    }

    .links_title {
        display: none;
    }

    .links {
        columns: auto;
        break-after: column;
    }
}
</style>
<i18n lang="json">{
    "en": {
        "copyright": "© Playgama, {year}",
        "developers": "Developers",
        "advertisers": "Advertisers",
        "publishers": "Publishers",
        "privacy_policy": "Privacy Policy",
        "contact_us": "Contact us",
        "categories_title": "Games Categories"
    }
}</i18n>
