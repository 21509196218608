import { defineStore } from 'pinia'
import { submitContactForm } from '@/utils/requests/form'
import { FormSubmitData } from '@/types'

export const useContactFormStore = defineStore('contactForm', () => {
    async function validateAndSubmitForm(data: FormSubmitData) {
        await submitContactForm(data)
    }

    return {
        validateAndSubmitForm,
    }
})
