<template>
    <RouterLink
        :to="props.link"
        :class="{
            [textStyle]: true,
            [styles.active]: props.isActive,
            [styles.category]: true,
            [styles.highlighted]: props.type === 'highlighted',
        }"
    >
        <Icon
            v-if="props.icon"
            :name="props.icon"
            :class="styles.categoryIcon"
        />
        {{ name }}
    </RouterLink>
</template>

<script setup lang="ts">
import {
    useCssModule,
} from 'vue'
import Icon, { type IconProps } from '@/components/Icon/index.vue'

interface CategoryProps {
    name: string
    link: string
    isActive: boolean
    icon?: IconProps['name']
    type?: 'highlighted',
    textStyle?: string,
}

const styles = useCssModule()
const props = withDefaults(defineProps<CategoryProps>(), {
    textStyle: 'accent',
})
</script>

<style module>

.category {
    display: flex;
    align-items: center;
    padding: var(--gap-small) var(--gap-medium);
    border-radius: var(--radius-medium);
    white-space: nowrap;
}

.category:hover {
    background-color: var(--dark-purple);
}

.category.active {
    background-color: var(--active);
}

.categoryIcon {
    margin-right: var(--gap-small);
}

.category.highlighted {
    color: var(--button-light);
    border: 1px solid var(--button-light);
}
</style>
