import type {
    PaginationType,
    CategoryDataType,
    GameType,
    CategoriesType,
    PlatformType,
    SimplifiedGame,
    GameRecommendationType,
    GameRecommendation,
    APICategoryDataType,
} from '@/types'
import { request } from '@/utils/requests/request'

type GamesAndCategoriesRequestParams = {
    categoriesIds?: CategoriesType[],
    platform: PlatformType,
    pagination?: PaginationType,
}

const DEFAULT_PAGINATION: PaginationType = {
    page: 1,
    pageSize: 1,
}

export async function getGamesByCategories({
    categoriesIds,
    platform,
    pagination = DEFAULT_PAGINATION,
}: GamesAndCategoriesRequestParams): Promise<CategoryDataType> {
    const path = '/api/v1/categories/games'
    const search = new URLSearchParams()
    search.set('pagination', JSON.stringify(pagination))
    search.set('platform', platform)
    if (categoriesIds) {
        search.set('categoriesIds', JSON.stringify(categoriesIds))
    }
    const { originalResponse, data } = await request(`${path}?${search}`)

    if (!originalResponse.ok) {
        return Promise.reject(originalResponse.status)
    }

    const gamesByCategories:APICategoryDataType = data
    return {
        categories: gamesByCategories.categories.map((category) => ({
            ...category,
            games: category.games.map((game):SimplifiedGame => ({
                hru: game.hru,
                title: game.title,
                preview: game.preview,
                video: game.videos?.[0] ? {
                    preview_url: game.videos[0].preview_url,
                } : undefined,
            })),
        })),
        pagination: gamesByCategories.pagination,
    }
}

export async function getGameByParam(param: string): Promise<GameType> {
    const URL = `/api/v1/games/${param}`
    try {
        const { originalResponse, data } = await request<GameType>(URL)
        if (!originalResponse.ok) {
            // возможно логировать причину - непонятно
            return Promise.reject(originalResponse.status)
        }

        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}

export async function getSimplifiedGames(hrus: string[]): Promise<SimplifiedGame[]> {
    const URL = `/api/v1/games/simplified?hrus=${hrus.join(',')}`
    try {
        const { originalResponse, data } = await request<SimplifiedGame>(URL)
        if (!originalResponse.ok) {
            // возможно логировать причину - непонятно
            return Promise.reject(originalResponse.status)
        }

        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}

export async function getManyGamesData(ids: string[]): Promise<SimplifiedGame[]> {
    const games = await getSimplifiedGames(ids)
    const filteredGames = games.filter((game) => game !== undefined) as GameType[]
    return filteredGames.map((game): SimplifiedGame => ({
        hru: game.hru,
        title: game.title,
        preview: game.preview,
        video: game.videos?.[0] ? {
            preview_url: game.videos[0].preview_url,
        } : undefined,
    }))
}

export async function getGameRecommendations<T extends GameRecommendationType[]>({
    hru,
    types,
    platform,
    limit = 32,
}: {
    hru: string,
    types: T,
    platform: PlatformType,
    limit?: number,
}): Promise<GameRecommendation<T>> {
    const search = new URLSearchParams()
    search.set('rec_types', types.join(','))
    search.set('limit', String(limit))
    search.set('platform', platform)

    const URL = `/api/v1/games/recommendations/${hru}?${search}`
    try {
        const { originalResponse, data } = await request<GameRecommendation<T>>(URL)
        if (!originalResponse.ok) {
            // возможно логировать причину - непонятно
            return Promise.reject(originalResponse.status)
        }

        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}
