import type { AdType } from '@/types'
import { Banner } from './banner'
import type { RewardedBanner } from './rewarded-banner'

export type BannerEventType = 'closed' | 'rewarded' | 'empty' | 'ready' | 'rendered' | 'viewable'

export type BannerEventListenerArgs = [
    type: BannerEventType,
    callback: (event: Event) => any,
]

export type PageAdType = 'sticky_portrait' | 'sidebar_desktop'

export enum AdProviderStatus {
    loading= 'loading',
    online = 'online',
    offline = 'offline'
}

export enum AdvAction {
    preloadRewarded = 'preloadRewarded',
    showRewarded = 'showRewarded',
    preloadInterstitial = 'preloadInterstitial',
    showInterstitial = 'showInterstitial',
}

export type PageAdSize = 'fluid' | [number, number]

export type PageAdOptions = {
    el?: string, // id of element where to mount the ad
    sizes?: PageAdSize[],
    type: PageAdType,
    refresh?: number // seconds
    clid?: string,
    gameId?: string,
    userId?: string,
}

export type AdvServiceConfig = {
    targeting?: Record<string, string | string[]>
}
export abstract class AdvService {
    abstract readonly serviceStatus: Promise<AdProviderStatus>

    abstract prepareOutOfPageAd(type: AdType): Promise<RewardedBanner>

    abstract requestPageAd(options: PageAdOptions): Promise<Banner>

    abstract updateTargeting(targeting: AdvServiceConfig['targeting']): void
}
