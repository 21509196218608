<template>
    <div :class="$style.rotate" v-if="shouldRotate">
        <img :src="RotateIcon" :alt="t('rotate')" :class="$style.icon" />
        <Typography type="header" size="m" :class="$style.title">{{ t('rotate') }}</Typography>
        <Typography type="paragraph" size="m" :class="$style.text">{{ t('message', { orientation: props.orientation[0] }) }}</Typography>
    </div>
</template>

<script lang="ts" setup>
import {
    computed, onMounted, onUnmounted, ref,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@/store/app-store'
import Typography from '@/components_new/Typography.vue'
import RotateIcon from './icons/rotate.svg'

type Orientation = 'horizontal' | 'vertical'
interface RotateMessageProps {
    orientation: Orientation[]
}

const props = defineProps<RotateMessageProps>()

const { t } = useI18n()

const { platform } = useAppStore()
const screenOrientation = ref<Orientation>('horizontal')
const shouldRotate = computed(() => {
    if (
        platform === 'desktop'
        || props.orientation.includes(screenOrientation.value)
    ) {
        return false
    }
    return true
})

const portraitMediaQuery = ref<MediaQueryList | null>(null)

const onOrientationChange = () => {
    screenOrientation.value = portraitMediaQuery.value?.matches ? 'vertical' : 'horizontal'
}

onMounted(() => {
    if (platform !== 'desktop') {
        portraitMediaQuery.value = window.matchMedia('(orientation: portrait)')
        portraitMediaQuery.value.addEventListener('change', onOrientationChange)
        onOrientationChange()
    }
})

onUnmounted(() => {
    portraitMediaQuery.value?.removeEventListener('change', onOrientationChange)
})
</script>

<style module>
.rotate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.icon {
    max-width: 50%;
    max-height: 50%;
    margin-bottom: 32px;
}

.title {
    margin-bottom: 12px;
}

.text {
    color: rgba(255, 255, 255, 0.8);
}
</style>

<i18n lang="json">{
    "en": {
        "rotate": "Rotate phone",
        "message": "The game supports only { orientation } orientation"
    }
}</i18n>
