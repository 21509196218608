<template>
    <Layout>
        <template #leftSide>
            <LeftSide
                :highlightCategories="menuHighlightCategoriesList"
                :categories="menuCategoriesList"
            />
        </template>
        <template #default>
            <h1>{{ t('title') }}</h1>
            <div :class="$style.formContainer">
                <ContactForm />
            </div>
        </template>
        <template #rightSide>
            <RightSide
                :recentlyGames="recommendedGames"
            />
        </template>
    </Layout>
</template>
<script setup lang="ts">
import {
    onServerPrefetch,
    onMounted,
} from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { useCategoriesStore } from '@/store/categories-store'
import Layout from '@/layouts/ThreeColumnsLayout.vue'
import LeftSide from '@/components/LeftSide.vue'
import RightSide from '@/components/RightSide.vue'
import ContactForm from '@/components/ContactForm/index.vue'

const { t } = useI18n()

const categoriesStore = useCategoriesStore()
const {
    contentCategoriesList,
    menuCategoriesList,
    menuHighlightCategoriesList,
    recommendedGames,
} = storeToRefs(categoriesStore)

const pageMeta = {
    title: 'Contact Us | Playgama 📞',
    meta: [
        {
            name: 'description',
            // eslint-disable-next-line max-len
            content: 'Get in touch with the Playgama team for any inquiries, support, or feedback. We\'re here to help!',
        },
    ],
}

useHead(pageMeta)

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

onMounted(async () => {
    // Prevents the request from being made twice if the data is already in the store
    if (contentCategoriesList.value.length) {
        return
    }
    await categoriesStore.setMainData()
})
</script>

<style module>
.formContainer {
    width: 100%;
    max-width: 728px;
    margin-top: var(--gap-xlarge);
}
</style>

<i18n lang="json">
{
    "en": {
        "title": "Contacts"
    }
}
</i18n>
