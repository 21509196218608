<template>
    <div :class="$style.header">
        <div :class="$style.leftBlock">
            <div :class="$style.menu" >
                <div :class="$style.short_menu">
                    <template v-if="categoriesModalOpened">
                        <Button @click="menuToggle" iconLeft="close" visual="text">
                            {{ t('close') }}
                        </Button>
                    </template>
                    <template v-else>
                        <button @click="menuToggle" style="display: flex; padding: 0">
                            <Icon name="menu" size="m" />
                        </button>
                        <div :class="$style.logo_wrapper" ref="logoWrapRef">
                            <Logo :class="$style.logo"/>
                            <Logo short :class="$style.logo_short"/>
                        </div>
                    </template>
                </div>
                <div :class="$style.wide_menu">
                    <Logo />
                    <Button @click="menuToggle" iconLeft="menu" visual="text" size="l">{{ t('all_games') }}</Button>
                </div>
            </div>
        </div>
        <div v-if="isUserInfoLoaded" :class="$style.rightBlock" ref="rightBlockRef">
            <template v-if="isAuthorized && user">
                <template v-if="route.name === 'userProfile'">
                    <UserAvatar
                        :class="$style.avatar"
                        :avatar="user.avatar"
                        :firstName="user.firstName"
                        :lastName="user.lastName"
                    />
                    <Button
                        color="graphite"
                        size="m"
                        icon="logout"
                        @click="logout"
                    />
                </template>
                <template v-else>
                    <RouterLink to="/profile">
                        <UserAvatar
                            :class="$style.avatar"
                            :avatar="user.avatar"
                            :firstName="user.firstName"
                            :lastName="user.lastName"
                        />
                    </RouterLink>
                    <RouterLink to="/profile" :class="$style.coins">
                        <Icon name="fennek_coin" :class="$style.coins_icon"/>
                        <Typography type="header" size="xs" :responsive="false" :class="$style.fennecs">
                            <Balance :balance="userBalance" />
                        </Typography>
                    </RouterLink>
                </template>
            </template>
            <template v-else>
                <Button :class="$style.login" size="m" @click="signin">{{ t('login') }}</Button>
            </template>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { RouterLink, useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import Logo from '@/components_new/Logo/Logo.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Button from '@/components_new/Button.vue'
import UserAvatar from '@/components_new/UserAvatar.vue'
import { useUserStore } from '@/store/user-store/user-store'
import { useLayoutStore } from '@/store/layout-store'
import Typography from '@/components_new/Typography.vue'
import Balance from '@/components_new/Balance.vue'
import { onMounted, ref, useCssModule } from 'vue'
import { useDebounceFn, useResizeObserver } from '@vueuse/core'

const logoWrapRef = ref<HTMLElement | null>(null)

const { t } = useI18n()

const userStore = useUserStore()
const styles = useCssModule()

const route = useRoute()
const router = useRouter()

function logout() {
    userStore.logout()
    router.replace('/shop')
}

const {
    user, /* taskStats, */ isAuthorized, userBalance, isUserInfoLoaded,
} = storeToRefs(userStore)

const layoutStore = useLayoutStore()

const { categoriesModalOpened } = storeToRefs(layoutStore)
function menuToggle() {
    layoutStore.setCategoriesModalOpened(!categoriesModalOpened.value)
}

function signin() {
    layoutStore.setSignInModalOpened(true)
}

function onResize() {
    const logoWrapRefWidth = logoWrapRef.value?.clientWidth
    const logo = logoWrapRef.value?.querySelector(`.${styles.logo}`)
    const logoShort = logoWrapRef.value?.querySelector(`.${styles.logo_short}`)
    logo.hidden = false
    logoShort.hidden = true
    if (logoWrapRefWidth <= logo?.clientWidth) {
        logo.hidden = true
        logoShort.hidden = false
    }
}

const debouncedOnResize = useDebounceFn(onResize, 50)
useResizeObserver(logoWrapRef, debouncedOnResize)

onMounted(() => {
    onResize()
})
</script>

<style module>

.header {
    background: #17171A;
    height: var(--global-header-pannel-height);
    gap: 12px;
    padding-left: calc(var(--global-padding-horizontal) + env(safe-area-inset-left));
    padding-right: calc(var(--global-padding-horizontal) + env(safe-area-inset-right));
}

.header, .leftBlock, .rightBlock, .coins, .wide_menu, .short_menu {
    display: flex;
    align-items: center;
}

.menu,
.logo_wrapper {
  display: flex;
  flex-grow: 1;
}
 /* for querySelector in onResize callback - otherwise we won't find elements by these classes */
.logo,
.logo_short {
  visibility: visible;
}

.leftBlock {
    gap: 16px;
    flex-grow: 1;
}

.wide_menu {
    gap: 12px;
}

.short_menu {
    color: #C7ABFF;
    gap: 8px;
    display: none;
}

.rightBlock {
    gap: 12px;
}

.coins {
    gap: 8px;
}

.coins_icon {
  --icon-width: 32px;
  --icon-height: 32px;
}

.avatar {
    width: 32px;
}

.fennecs {
  color: #FFDCA1
}

@media (--mobile) {
    .wide_menu {
        display: none;
    }

    .short_menu {
        display: flex;
        flex-grow: 1;
    }

    .rightBlock {
        gap: 8px;
    }

    .coins {
        font-size: 16px;
        gap: 6px;
    }
}
</style>
<i18n lang="json">{
    "en": {
        "all_games": "All games",
        "login": "Login & Earn",
        "close": "Close"
    }
}</i18n>
