export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "404_imgAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error not found"])},
        "404_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level not found"])},
        "404_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the page you requested does not exist on this site"])},
        "404_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
        "404_randomGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random game"])},
        "500_imgAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error something went wrong"])},
        "500_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
        "500_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try reloading page. We're working hard to fix it for you as soon as possible"])},
        "500_reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload page"])},
        "500_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])}
      }
    }
  })
}
