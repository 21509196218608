<template>
    <PromoLine :label="props.label">
        <template #default="{ handleClick }">
            <Button @click="handleClick('invite')">
                {{ t('invite_btn') }}
            </Button>
        </template>
    </PromoLine>
</template>
<script setup lang="ts">

import Button from '@/components_new/Button.vue'
import PromoLine from '@/components_new/PromoItems/PromoHint.vue'
import { useI18n } from 'vue-i18n'
import { promoItemsTranslation } from '@/components_new/PromoItems/utils'

const { t } = useI18n({
    messages: {
        en: {
            ...promoItemsTranslation.en,
        },
    },
})

interface PromoInviteHintProps {
  label?: string
}

const props = withDefaults(defineProps<PromoInviteHintProps>(), {
    label: '20k',
})
</script>
