import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLayoutStore = defineStore('layout', () => {
    const fullScreen = ref(false)

    function setFullscreen(value: boolean) {
        fullScreen.value = value
    }

    const categoriesModalOpened = ref(false)

    function setCategoriesModalOpened(value: boolean) {
        categoriesModalOpened.value = value
    }

    const inviteModalOpened = ref(false)

    function setInviteModalOpened(value: boolean) {
        inviteModalOpened.value = value
    }

    const signInModalOpened = ref(false)

    function setSignInModalOpened(value: boolean) {
        signInModalOpened.value = value
    }

    return {
        setFullscreen,
        fullScreen,
        setCategoriesModalOpened,
        categoriesModalOpened,
        setInviteModalOpened,
        inviteModalOpened,
        setSignInModalOpened,
        signInModalOpened,
    }
})
