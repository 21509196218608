<template>
    <component :is="props.noLink ? 'div' : 'RouterLink'" to="/" :class="[{[$style.short]: props.short}, $style.logo]">
        <img :src="props.short ? logoShortImg : logoImg " :alt="t('alt')"/>
    </component>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import logoImg from './images/logo.svg'
import logoShortImg from './images/logo-short.svg'

const { t } = useI18n()

interface LogoProps {
    short?: boolean
    noLink?: boolean
}

const props = withDefaults(defineProps<LogoProps>(), {
    short: false,
    noLink: false,
})
</script>
<style module>
.logo {
    color: #C7ABFF;
    width: var(--logo-width, 138px);
    height: var(--logo-height, 30px);
}

.logo.short {
  --logo-width: 32px;
  --logo-height: 30px;
}
</style>

<i18n lang="json">{
  "en": {
    "alt": "Playgama logo"
  }
}</i18n>
