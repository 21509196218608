<template>
    <component :is="iconsList[props.name]" />
</template>

<script setup lang="ts">
import { IconNameType } from '@/components_new/Icon'
import { iconsList } from './icons-list'

export interface IconProps {
    name: IconNameType
}

const props = defineProps<IconProps>()

</script>
