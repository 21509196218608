export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "relatedGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related"])},
        "spotlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotlight"])},
        "recommendedGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
        "random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Random"])}
      }
    }
  })
}
