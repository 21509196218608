export function parseCookie(cookie: string) {
    return cookie
        .split('; ')
        .reduce<Record<string, string>>((acc, el) => {
            const [key, value] = el.split('=')
            acc[key] = decodeURIComponent(value)
            return acc
        }, {})
}

export function getUACFromCookies(cookie: string) {
    const cookies = parseCookie(cookie)
    if (cookies.pg_uac) {
        try {
            return JSON.parse(atob(cookies.pg_uac)) as Record<string, string>
        } catch (e) {
            return {}
        }
    }
    return {}
}
