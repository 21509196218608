export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title_desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards – soon"])},
        "title_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards • soon"])},
        "alt_stay_tuned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay tuned"])}
      }
    }
  })
}
