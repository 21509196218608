<template>
    <form
        :class="$style.form"
        @submit.prevent="handleSubmitContact"
    >
        <label
            :class="$style.label"
            class="accentSemibold"
            for="form-message"
        >
            {{t('messageLabel')}}
            <textarea
                v-model="formData.message"
                required
                id="form-message"
                name="message"
                :class="$style.messageTextarea"
                :placeholder="t('messagePlaceholder')"
            />
        </label>
        <label :class="$style.label" class="accentSemibold" for="form-email">
            {{t('emailLabel')}}
            <input
                v-model="formData.email"
                required
                id="form-email"
                name="email"
                :class="$style.emailInput"
                placeholder="mail@example.com"
                type="email"
            />
        </label>
        <Button
            :class="$style.submit"
            :title="t('submit')"
            size="m"
            type="submit"
            :disabled="isButtonDisabled"
        />
    </form>
</template>
<script setup lang="ts">
import {
    ref,
    computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { FormSubmitData } from '@/types'
import Button from '@/components/Button.vue'
import { useContactFormStore } from './contact-form-store'

const DEFAULT_EMAIL = ''
const DEFAULT_MESSAGE = ''

const { t } = useI18n()
const formData = ref<FormSubmitData>({
    email: DEFAULT_EMAIL,
    message: DEFAULT_MESSAGE,
})
const isSubmitting = ref(false)
const isButtonDisabled = computed(() => isSubmitting.value
    || Object.values(formData.value).some((value) => !value))

const props = defineProps<{page?: string}>()

/* eslint-disable-next-line no-unused-vars */
const emit = defineEmits<{(e: 'submitted'): void}>()

function handleSubmitContact() {
    isSubmitting.value = true
    useContactFormStore()
        .validateAndSubmitForm(
            {
                ...formData.value,
                page: props.page,
            },
        )
        .then(() => {
            formData.value = {
                email: DEFAULT_EMAIL,
                message: DEFAULT_MESSAGE,
            }
        })
        .finally(() => {
            emit('submitted')
            isSubmitting.value = false
        })
}
</script>

<style module>
.form {
    display: flex;
    flex-direction: column;
    gap: var(--gap-large)
}

.submit {
    width: fit-content;
    margin-left: auto;
}

.label {
    display: flex;
    flex-direction: column;
    gap: var(--gap-regular);
}

.emailInput,
.messageTextarea {
    padding: var(--gap-medium);
    border: 1px solid var(--white-25);
    border-radius: var(--radius-small);
}

</style>

<i18n lang="json">
{
    "en": {
        "messageLabel": "Message",
        "messagePlaceholder": "Please provide as much information as possible",
        "emailLabel": "Your Email",
        "submit": "Send"
    }
}
</i18n>
