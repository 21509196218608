export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "feeling_lucky": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm feeling lucky"])},
        "end_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The End"])}
      }
    }
  })
}
