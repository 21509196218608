<template>
    <div :class="$style.container" ref="containerRef">
        <Tooltip
            :content="tooltipContent"
            :class="$style.tooltip"
        >
            <span ref="spanRef" :class="$style.text"></span>
        </Tooltip>
    </div>
</template>
<script lang="ts" setup>
import {
    computed,
    onMounted,
    ref,
    watch,
    useCssModule,
} from 'vue'
import {
    useResizeObserver,
    useDebounceFn,
    useTransition,
    TransitionPresets,
} from '@vueuse/core'
import Tooltip from '@/components_new/Tooltip.vue'

const props = defineProps<{
    balance: number
}>()

const styles = useCssModule()

function formatFullValue(value: number) {
    if (value < 10_000) {
        return value.toString()
    }
    return value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
}
function getValues({ maxChars, value }: {maxChars: number, value: number}): {display: string, tooltip: string | undefined} {
    const displayFullValue = formatFullValue(value)
    const displayFullValueChars = displayFullValue.length

    if (value < 1000) {
        return { display: displayFullValue, tooltip: undefined }
    }

    if (value < 1_000_000) {
        if (maxChars < displayFullValueChars) {
            return { display: `${Math.floor(value / 1000)}k`, tooltip: displayFullValue }
        }
        return { display: displayFullValue, tooltip: undefined }
    }

    if (maxChars < displayFullValueChars) {
        return { display: `${Math.floor(value / 1_000_000)}M`, tooltip: displayFullValue }
    }
    return { display: displayFullValue, tooltip: undefined }
}

const tooltipContent = ref<string | undefined>(undefined)
const containerRef = ref<HTMLElement | null>(null)
const spanRef = ref<HTMLElement | null>(null)
const maxChars = ref<number | undefined>(undefined)
const computedBalance = computed(() => props.balance) // to enable reactivity on useTransition
const animatedBalance = useTransition(computedBalance, {
    duration: 1000,
    transition: TransitionPresets.linear,
    onStarted: () => {
        spanRef.value?.classList.add(styles.bouncing)
    },
    onFinished: () => {
        spanRef.value?.classList.remove(styles.bouncing)
    },
})

function renderValues() {
    if (maxChars.value === undefined || !spanRef.value) {
        return
    }

    const { display, tooltip } = getValues({ maxChars: maxChars.value, value: Math.round(animatedBalance.value) })
    tooltipContent.value = tooltip
    spanRef.value.textContent = display
}

watch(animatedBalance, renderValues)

function onResize() {
    const container = containerRef.value
    const span = spanRef.value
    if (!container || !span) {
        return
    }

    span.textContent = ''
    const availableWidth = container.clientWidth
    span.textContent = 'X' // 1 wide character to measure
    const charWidth = span.clientWidth

    maxChars.value = Math.floor(availableWidth / charWidth)

    renderValues()
}

const debouncedOnResize = useDebounceFn(onResize, 100)
useResizeObserver(containerRef, debouncedOnResize)

onMounted(() => {
    onResize()
})
</script>
<style module>
.container {}

.text, .tooltip {
    display: inline-block;
}

.text {
    /* turn font into monospace for less shaking animation */
    font-variant-numeric: tabular-nums;
}

.bouncing {
    animation-name: bouncing;
    animation-delay: .1s;
    animation-duration: .2s;
    animation-iteration-count: infinite;
}
@keyframes bouncing {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1, 1.2);
    }
}
</style>
