import type { BannerEventListenerArgs, BannerEventType } from '@/modules/adv/index'

type BannerOptions = {
    destroy?: () => void
}
export class Banner {
    private readonly eventBus: HTMLElement

    private closed = false

    readonly destroy: () => void

    constructor(options: BannerOptions) {
        this.eventBus = document.createElement('b')
        this.destroy = options.destroy || (() => {})
    }

    triggerClosed() {
        if (!this.closed) {
            this.closed = true
            this.dispatch('closed')
        }
    }

    triggerRewarded(payload: unknown) {
        this.dispatch('rewarded', payload)
    }

    triggerEmpty() {
        this.dispatch('empty')
    }

    triggerViewable() {
        this.dispatch('viewable')
    }

    triggerRendered() {
        this.dispatch('rendered')
    }

    addEventListener(...args: BannerEventListenerArgs) {
        this.eventBus.addEventListener(...args)
    }

    removeEventListener(...args: BannerEventListenerArgs) {
        this.eventBus.removeEventListener(...args)
    }

    dispatch(event: BannerEventType, payload?: unknown) {
        this.eventBus.dispatchEvent(new CustomEvent<unknown>(event, { detail: payload }))
    }
}
