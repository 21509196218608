import { PlatformType } from '@/types'

export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function pickManyRandomElements<T>(arr: T[], count: number): T[] {
    const copyArr = JSON.parse(JSON.stringify(arr))
    const result: T[] = []
    if (arr.length <= count) {
        return copyArr
    }
    for (let i = 0; i < count; i += 1) {
        const randomIndex = Math.floor(Math.random() * copyArr.length)
        result.push(copyArr[randomIndex])
        copyArr.splice(randomIndex, 1)
    }
    return result
}

export function getCurrentPlatform(userAgent: string): PlatformType {
    if (userAgent.match(/(iPhone|iPad|iPod)/)) {
        return 'ios'
    }
    if (userAgent.match(/Android/)) {
        return 'android'
    }
    return 'desktop'
}

export function getRandomNumber(to: number, from = 0, radix = 10): string {
    return (from + Math.round(Math.random() * (to - from))).toString(radix)
}

export function calculateDifferenceInDays(date1: Date, date2: Date) {
    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())

    const timeDiff = Math.abs(utc2 - utc1)
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
}

// 4 значащие цифры
export function reducePriceTag(value?: number): string {
    if (!value && value !== 0) {
        return ''
    }
    const roundedValue = parseFloat(value.toPrecision(4))

    if (roundedValue < 1000) {
        return roundedValue.toString()
    }
    if (roundedValue >= 1000 && roundedValue < 1000 * 1000) {
        return `${roundedValue / 1000}k`
    }

    if (roundedValue >= 1000 * 1000 && roundedValue < 1000 * 1000 * 1000) {
        return `${roundedValue / (1000 * 1000)}m`
    }

    if (roundedValue >= 1000 * 1000 * 1000 && roundedValue < 1000 * 1000 * 1000 * 1000) {
        return `${roundedValue / (1000 * 1000 * 1000)}b`
    }

    return `${roundedValue / (1000 * 1000 * 1000 * 1000)}t`
}

export function toBoolean(value: string): boolean {
    return value === 'true'
}
