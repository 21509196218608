export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desktop"])},
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
        "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iOS"])},
        "platforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platforms"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
        "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
        "showFullDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all description"])}
      }
    }
  })
}
